const env = process.env;
let url = (window.location.host.indexOf("localhost") !== -1) ? '' : env.REACT_APP_HOST;
let urlWithControllers = url + "/Controllers/";
const apis = {
    url: url,
    imgBaseUrl: env.REACT_APP_HOST,
    login: urlWithControllers + 'Login.ashx',
    SurveyQuestion: urlWithControllers + 'SurveyQuestion.ashx',
    Survey: urlWithControllers + 'Survey.ashx',
    Asset: urlWithControllers + 'Asset.ashx',
    Attachment: `${urlWithControllers}Attachment.ashx`,
    Location: `${urlWithControllers}Location.ashx`,
    ClientLogoBaseUrl: urlWithControllers + 'CoolerImagePreview.ashx',
    LocationPromotion: urlWithControllers + 'LocationPromotion.ashx',
    SurveyType: urlWithControllers + "SurveyType.ashx",
    Thumbnail: urlWithControllers + "Thumbnail.ashx",
    SurveyDetails: urlWithControllers + "SurveyDetails.ashx",
    StitchImages: urlWithControllers + "CoolRAPI.ashx",
    storeName: {
        REQ_LocationList: "REQ_LocationList",
        REQ_SurveyList: "REQ_SurveyList",
        REQ_LoadSurveyById: "REQ_LoadSurveyById",
        REQ_SurveyDetails: "REQ_SurveyDetails",
        REQ_SurveyType: "REQ_SurveyType",
        REQ_UserSurveyCount: "REQ_SurveyCount",
        REQ_LocationSurveyTypeData: "REQ_LocationSurveyTypeData"
    }
}

export default apis;