const actions = {
  SET_SELECTED_PRODUCT: 'selectedProduct',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_USER_PASSWORD: 'SET_USER_PASSWORD',
  SET_CLIENT_SELECT: 'SET_CLIENT_SELECT',
  SET_CLIENT_LIST_DATA: 'SET_CLIENT_LIST_DATA',
  SET_COMBO_DATA: 'SET_COMBO_DATA',
  RESET_STATE: 'RESET_STATE',
  SET_GEO_LOCATION_DATA: 'SET_GEO_LOCATION_DATA',
  UPDATE_LOADER_STATE: 'UPDATE_LOADER_STATE',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_APP_INFO: 'SET_APP_INFO',
  SET_SCENE_PICTURES: 'SET_SCENE_PICTURES',
  SET_SCENE_PICTURES_CLONE: 'SET_SCENE_PICTURES_CLONE',
  SET_CAPTURE_MATRIX: 'SET_CAPTURE_MATRIX',
  SET_CAPTURE_MATRIX_CLONE: 'SET_CAPTURE_MATRIX_CLONE',
  SET_CAPTURE_SCENE_QUESTION_IDS: 'SET_CAPTURE_SCENE_QUESTION_IDS',
  SET_CAPTURE_SCENE_QUESTION_IDS_CLONE: ' SET_CAPTURE_SCENE_QUESTION_IDS_CLONE',
  SET_CAPTURE_SCENE_SERIAL_NUMBERS: "SET_CAPTURE_SCENE_SERIAL_NUMBERS",
  SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE: "SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE",
  SET_CURRENT_LOCAL_LANGUAGE: 'SET_CURRENT_LOCAL_LANGUAGE',
  SET_UPLOAD_IN_PROGRESS: "SET_UPLOAD_IN_PROGRESS",
  SET_ANSWER_DATA: "SET_ANSWER_DATA",
  SET_DEPENDDENCY_QUESTION: "SET_DEPENDDENCY_QUESTION",
  SET_POSQUESTION: "SET_POSQUESTION",
  SET_POS_SERIAL_NUMBERS: "SET_POS_SERIAL_NUMBERS",
  SET_IMAGE_TO_STITCH: "SET_IMAGE_TO_STITCH",
  SET_FILE_TO_UPLOAD: "SET_FILE_TO_UPLOAD",
  SET_IMAGE_SEQUENCE: "SET_IMAGE_SEQUENCE",
};

export default actions;