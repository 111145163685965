import React, { memo, useEffect, useState } from 'react';
import Header from '../../Component/Header';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@mui/material';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import Utils from '../../Utils';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import StorageIcon from '@mui/icons-material/Storage';

dayjs.extend(utc);

const SurveyList = (props) => {
    const history = useHistory();
    const { match } = props;
    const { LocationId } = match.params;
    const [surveyList, setSurveyList] = useState({ records: [], recordCount: 0 });
    const [startIndex, setStartIndex] = useState(0);
    const { t } = useTranslation();
    const userData = useSelector(state => state.appReducer.userData);
    const isShowUserInfo = props.location.state?.isShowUserInfo;

    useEffect(() => {
        getSurveyList()
    }, [startIndex]);

    const fetchMoreData = () => {
        setStartIndex(startIndex + 100);
    }

    const getSurveyList = async () => {
        const params = {
            storeName: apis.storeName.REQ_SurveyList,
            action: 'list',
            asArray: 0,
            start: startIndex,
            limit: 100,
            locationId: LocationId ? LocationId : 0,
            sort: 'SurveyDateTime',
            dir: 'DESC'

        };
        if (isShowUserInfo) {
            params["ModifiedByUser"] = userData.tags.Username;
        }
        const response = await request({ url: apis.Survey, params, history, isOfflineModeEnable: true, defaultData: { records: [] } });
        if (response && response.records) {
            let reorderLogsDataClone = [...surveyList.records];
            const reorderLogsData = startIndex > 0 ? Utils.getFilterData(response.records, reorderLogsDataClone, "SurveyId") : [];
            setSurveyList({ records: startIndex == 0 ? response.records : reorderLogsData, recordCount: response.recordCount });
        }
    }
    const openSurvey = (record) => {
        history.push(`/Survey/${record.LocationId}/${record.SurveyTypeId}/${record.SurveyId}`);
    }
    const onBackClick = () => {
        history.push('/SurveyReport');
    }

    return (
        <>
            <Header clientIcon={true} title="Surveys" isShowBackButton={isShowUserInfo} onBackClick={onBackClick} isShowMenuOption={!isShowUserInfo} showDotOnly={isShowUserInfo} />
            <InfiniteScroll
                dataLength={surveyList.records.length}
                next={fetchMoreData}
                hasMore={true}
            >

                <div style={{ padding: "0rem 0.7rem", position: "relative", top: "-1rem" }}>
                    <List>
                        {surveyList?.recordCount > 0 ?
                            surveyList.records.map((survey, index) => {
                                return (

                                    <div key={index}>
                                        {index > 0 && <Divider variant="inset" component="li" />}

                                        <ListItem alignItems="flex-start" sx={{ paddingLeft: "0px" }} >
                                            <ListItemAvatar >
                                                <Avatar sx={{ bgcolor: "primary.main" }} onClick={() => openSurvey(survey)}><StorageIcon /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={survey.OutletName}
                                                secondary={
                                                    <React.Fragment>
                                                        <div>{survey.Category} {survey.SurveyCategory} - {survey.SurveyType}</div>
                                                        {!LocationId &&
                                                            <div>{survey.ModifiedByUser}</div>
                                                        }
                                                        <div>{dayjs.utc(survey.EndDate).format(Utils.DATE_TIME_FORMAT)} {isShowUserInfo ? (" " + survey.DurationSecond + "s ") : ""} <Typography sx={{ color: "primary.main", display: "inline", fontSize: "inherit!important" }}>{survey.Latitude.toFixed(Utils.DECIMAL_POSITION)} / {survey.Longitude.toFixed(Utils.DECIMAL_POSITION)}</Typography> </div>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </div>

                                )
                            })

                            : <div style={{ textAlign: "center" }}><h3 className="text-center">{t("No Available Survey!")}</h3></div>
                        }
                    </List>
                </div>
            </InfiniteScroll>
        </>
    )
}
export default memo(SurveyList)