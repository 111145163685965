
import React, { useState, useEffect, useMemo } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import coolerImage from "./cooler.png";
import Utils from "../../Utils";

function Map(props) {
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [noLocationHasCoordinate, setNoLocationHasCoordinate] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState({ lat: 0, lng: 0 })

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedOutlet(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);


  const getMarkers = (locations) => {

    if (locations) {
      const markers = locations.map((outlet, key) => {
        noLocationHasCoordinate && setNoLocationHasCoordinate(false);
        if (defaultLocation.lat === 0 && defaultLocation.lng === 0) {
          setDefaultLocation({ lat: outlet.Latitude, lng: outlet.Longitude })
        }
        return <Marker
          key={key}
          position={{
            lat: outlet.Latitude,
            lng: outlet.Longitude
          }}
          onClick={() => {
            setSelectedOutlet(outlet);
          }}
          icon={{
            url: coolerImage,
            scaledSize: new window.google.maps.Size(25, 25)
          }}
        />

      })
      return markers;
    }

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allMarkers = useMemo(() => getMarkers(props.locations), [props.locations])

  const onToggleInfoOpen = () => {
    setSelectedOutlet(null);
  }

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={defaultLocation}
    >
      {allMarkers}

      {selectedOutlet && (
        <InfoWindow
          onCloseClick={onToggleInfoOpen}
          position={{
            lat: selectedOutlet.Latitude,
            lng: selectedOutlet.Longitude
          }}
        >
          <div>
            <h2>Name: {selectedOutlet.Name}</h2>
            <p>{selectedOutlet.City}, {selectedOutlet.Country}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

const MapWrapper = withScriptjs(withGoogleMap(Map));

const MapSearch = ({ locations, handleMapClick }) => {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MapWrapper
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Utils.Google_ApiKey}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        locations={locations}
        handleMapClick={handleMapClick}
      />
    </div>
  )
}

export default MapSearch;

