//NPM Packages
import React, { useState } from 'react';
import { Box, Button, TextField, FormLabel, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
//apis and handlers
import { request, apis } from "../../httpUtil/";
import CommonAlert from '../../Component/CommonAlert';

const forgotPasswordMessages = {
    2: "Duplicate User",
    3: "Invalid Username/Email",
    5: "A new OTP has been sent to the primary email address.",
    6: "Invalid OTP.",
    7: "Valid OTP",
    8: "OTP Expired",
    9: "Password changed successfully",
    10: "New password cannot match with in the last 5 passwords."
}

const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,20}$");

const ForgotPasswordModal = ({ isOpen, handleCloseModal }) => {
    const dispatch = useDispatch(), history = useHistory();
    const [textValue, setTextValue] = useState([]);
    const [error, setError] = useState({});
    const [isAlreadyHaveOTP, setIsAlreadyHaveOTP] = useState(false);
    const [alertStatus, setAlertStatus] = useState({ open: false, alertMessage: '', severity: 'error' });
    const handleChange = (event) => {
        const { name, value } = event?.target;
        setTextValue((textValue) => ({ ...textValue, [name]: value }));
        validate({ [name]: value });
      };

    function validate(fieldValue) {
        let msg = { ...error };
        const { Username, Email, ConfirmPassword, newPassword, tokenKey } = fieldValue
        if ("Email" in fieldValue || "Username" in fieldValue) {
            const input = (Email || Username);
            if (input) {
                const emailValidation = emailRegex.test(input);
                msg[Object.keys(fieldValue)] = emailValidation ? "" : "Invalid Username/Email";
            }
        }
        if ("newPassword" in fieldValue) {
            if (newPassword) {
                const isPasswordValid = passwordRegex.test(newPassword);
                msg.newPassword = isPasswordValid ? "" : "Password must contain minimum 7 and 20 maximum characters and have at least one upper , small character , number and special character."
            }
        }
        if ("ConfirmPassword" in fieldValue) {
            if (ConfirmPassword) {
                msg.ConfirmPassword = textValue.newPassword !== ConfirmPassword ? "Password and confirm password should be same." : "";

            }
        }
        setError({ ...msg });
    };

    const handleCloseAlert = () => {
        setAlertStatus({ open: false, alertMessage: '',severity:'error' });
    }
    const handleSubmit = async event => {
        let params = Object.assign({});
        if (event) event.preventDefault();
        const { Username = '', Email = '', tokenKey, newPassword, ConfirmPassword } = textValue;
        let validationMessage = '', message = '';
        if (!Email && !Username) {
            validationMessage = "You must enter username or email"
        } else if (isAlreadyHaveOTP && newPassword == null && ConfirmPassword == null) {
            validationMessage = "Password or Confirm Password can not be blank";
        }
        if (validationMessage) {
            setAlertStatus({ open: true, alertMessage: validationMessage, severity: 'error' });
            return false;
        }
        if (!isAlreadyHaveOTP) {
            params = { action: 'recoverpassword', Username: Username, Email: Email }
        } else {
            params = { action: 'recoverpassword', Username: Username, Email: Email, tokenKey: tokenKey, newPassword: newPassword, ConfirmPassword: ConfirmPassword }
        }
        if (!emailRegex.test(Email)) {
            setAlertStatus({ open: true, alertMessage: 'Invalid email', severity: 'error' });
            return false;
          }
        const { success, moreInfo, error, info } = await request({ url: apis.login, params, history, withBar: dispatch });
        if (error) {
            message = error;
        }
        if (success) {
            if (info) {
                if (info === "5") {
                    enableAllFields();//when otp send all fields are enable
                    message = moreInfo;
                } else {
                    message = forgotPasswordMessages[Number(info)];
                }
            } else {
                handleCloseModal(); //CLosing the form if there is no msg 
                return false;
            }
        }

        setAlertStatus({ open: true, alertMessage: `Password recovery ${message}`, severity: 'success' });
        handleCloseModal();
    };
    const enableAllFields = e => setIsAlreadyHaveOTP(!isAlreadyHaveOTP);

    return (
        <>

            <Dialog
                open={true}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description" >
                <DialogTitle>Forgot Password </DialogTitle>
                <DialogContent component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="off"
                        autoFocus
                    />
                    <Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={handleChange}
                            name="Email"
                            label="E-mail"
                            autoComplete="off"
                        />
                        <FormLabel >{error['Email']}</FormLabel >
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        name="tokenKey"
                        label="OTP"
                        autoComplete="off"
                        disabled={!isAlreadyHaveOTP}
                    />
                    <Box>
                        <TextField
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            name="newPassword"
                            label="New Password"
                            type="password"
                            autoComplete="off"
                            invalid={error.newPassword}
                            disabled={!isAlreadyHaveOTP}
                        />
                        <FormLabel >{error['newPassword']}</FormLabel >
                    </Box>
                    <Box>
                        <TextField
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            name="ConfirmPassword"
                            label="Confirm Password"
                            type="password"
                            autoComplete="off"
                            invalid={error.newPassword}
                            disabled={!isAlreadyHaveOTP}
                        />
                        <FormLabel >{error['ConfirmPassword']}</FormLabel >
                    </Box>


                </DialogContent>
                <DialogActions direction="row" spacing={2}>
                    <Button variant="contained" onClick={(e) => enableAllFields(e)} > Already have OTP?</Button>
                    <Button type="submit" variant="contained" onClick={() => handleSubmit()}> {isAlreadyHaveOTP ? "Change Password" : "Generate OTP"}</Button>
                    <Button className="forgot-password-cancel" variant="contained" onClick={() => handleCloseModal()}>Cancel</Button>
                </DialogActions>

            </Dialog>
            <CommonAlert
                open={alertStatus.open}
                handleClose={handleCloseAlert}
                severity={alertStatus.severity}
                message={alertStatus.alertMessage}
            />
        </>
    );
}

export default ForgotPasswordModal