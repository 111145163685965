/* eslint-disable array-callback-return */
import React, { PureComponent } from 'react';
import actions from '../../redux/actions'
import { Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem, ListItemAvatar, ListItemText, Avatar, Grid, Box, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiCarousel from "../../Component/MuiCarousel";
import VisibilitySensor from "react-visibility-sensor";
import { request } from '../../httpUtil/index';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { FaCamera } from "react-icons/fa";
import "./coolr-survey.css";
import apis from '../../httpUtil/apis';
import Utils from '../../Utils';
import Header from '../../Component/Header';
import template from '../../Component/template';
import { FaImage } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dbUtil from '../../Utils/dbUtil';
import { BiX, BiBarcodeReader } from "react-icons/bi";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import Capture from '../../Component/Capture/SceneCapture';
import { withTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import BarcodeComponent from '../../Component/Barcode/BarcodeComponent';
import { addRepeatedQuestions } from '../../Utils/addRepeatedQuestions';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SecondaryHeader from '../../Component/SecondaryHeader';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import CommonDialog from '../../Component/CommonDialog';
import CommonAlert from '../../Component/CommonAlert';

const topArrow = require("./.././../Assets/icon/top-arrow.png");
const bottomArrow = require("./.././../Assets/icon/bottom-arrow.png");
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const AssessmentType = {
    Radio: 'radio',
    Checkbox: 'checkbox',
    Text: 'text',
    Textarea: 'textarea',
    Select: 'select',
    File: 'file',
    MultiFile: 'multiFile',
    Number: 'number',
    barcode: 'barcode',
    Scene: 'scene',
    MultiFileStitch: 'multiFileStitch',
    date: "date"
};
const allowedFileTypes = ['file', 'multiFile', 'scene', 'multiFileStitch', 'barcode'];
class Survey extends PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            typeQuestion: [],
            activeType: 0,
            data: [],
            enableScroll: false,
            questionAndAnswer: [],
            questionNumber: [],
            selectedValue: [],
            locationData: {},
            showPlanogramPopup: false,
            planogramImageUrl: '',
            planogramName: '',
            openImagePop: { isOpen: false, imageId: '', url: '', attachementItem: [] },
            openMoreInfo: { isOpen: false, text: '' },
            startDate: null,
            assets: {},
            promotions: {},
            readOnlyView: false,
            activeIndex: 0,
            animating: false,
            openHTML5Scaner: false,
            scanResult: '',
            barcodeQId: '',
            closebarCodeBtn: false,
            showPictureCapture: false,
            captureQuestName: "",
            captureQuestType: "",
            captureActQuest: "",
            captureQuestNum: 0,
            captureSceneIndex: null,
            currentCaptureSerialNumber: "",
            stichedImageGuid: "",
            scannerType: "barcode",
            expanded: false,
            readerType: Utils.BarcodeReaderTypeList[0].value,
            imageUrl: "",
            barcodeValueDecoded: "",
            questionByAsset: {},
            barcodeScanFailureMessage: "Failed to decode barcode!",
            scanning: false,
            barcodeimg: "",
            currentBarcodeQuestion: {},
            repeatCount: 1,
            repeatedIds: [],
            openSurveyDialog: false,
            openAlert: false,
            alertMessage: ''
        }
        this.scrollContent = this.scrollContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRadioDblClick = this.onRadioDblClick.bind(this);
        this.onSaveSurveyData = this.onSaveSurveyData.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.cancelCapture = this.cancelCapture.bind(this);
        this.handleOpenCaptureScene = this.handleOpenCaptureScene.bind(this);
        this.handleAcordionChange = this.handleAcordionChange.bind(this);
        this.toggleScanning = this.toggleScanning.bind(this);
        this.getScannerResult = this.getScannerResult.bind(this);
        this.setbarcodeimg = this.setbarcodeimg.bind(this);
        this.getImageofFailedScan = this.getImageofFailedScan.bind(this);
    }
    isEmpty(v, allowBlank) {
        if (typeof v === 'object' || typeof v === 'function') {
            let i;
            for (i in v) {
                if (v.hasOwnProperty(i)) {
                    return false;
                }
            }
            return true;
        } else {
            // not an object or function - same as before
            return v === null || v === undefined || (!allowBlank ? v === '' : false);
        }
    }
    sectionLoaded() {
        let answers = document.getElementsByClassName('assementAnswer'), answer;
        for (let index in answers) {
            answer = answers[index];
            if (answer.addEventListener) {
                answer.addEventListener('mouseover', this.scrollInnerArea.bind(this, answer, index));
            }
        }
        let radioButtonCollection = document.getElementsByClassName("radioButton");
        for (let indexRadio in radioButtonCollection) {
            let radioField = radioButtonCollection[indexRadio];
            if (radioField.addEventListener) {
                radioField.addEventListener("dblclick", this.onRadioDblClick);
            }
        }
        this.setState({ enableScroll: true });

    }
    cancelCapture() {
        const { dispatch } = this.props;
        dispatch({ type: actions.SET_CAPTURE_MATRIX_CLONE, captureMatrixClone: {} });
        dispatch({ type: actions.SET_SCENE_PICTURES_CLONE, scenePicturesTakenClone: {} });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE, captureSceneQuestionIdsClone: [] })
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE, captureSceneSerialNumbersClone: {} });
        this.setState({ showPictureCapture: false });
    }
    componentWillUnmount() {
        // We need to clear all the data once user will back to the home screen and try to start new survey 
        const { history } = this.props;
        if (history.action === "POP") {
            this.cancelConfirmation()
        }
    }

    componentDidMount() {
        document.getElementById("top-scroller-icon")?.addEventListener("click", this.scrollContent, true);
        document.getElementById("bottom-scroller-icon")?.addEventListener("click", this.scrollContent, true);
        const { match, history } = this.props;
        const { params } = match;

        if (params.SurveyId) {
            this.getSubmittedSurveyData();
        }

        this.setState({ startDate: new Date() });
        if (!Number(params.LocationId)) {
            swal({ title: 'Please select Location First', icon: 'warning' });
            history.push('/Location');
            return;
        }
        this.getLocationDetail()
    }

    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        const { params } = match;
        if (this.state.repeatCount > prevState.repeatCount) {
            if (!params.SurveyId) {
                this.updateQuestionJson();
            }
        }
        let answerArray = this.props.answerData;
        const fileAnswerArray = answerArray.filter(e => e.type === 'file') || [];
        if (fileAnswerArray.length) {
            fileAnswerArray.forEach(image => {
                if (image.attachment && image.attachment.length) {
                    let imageItem = [...image.attachment];
                    imageItem.forEach(item => {
                        let cvs = document.getElementById(`image-view${item.name}`);
                        if (cvs && cvs.getContext) {
                            let img1 = this.randerImage(cvs)
                            img1.src = URL.createObjectURL(item);
                        }
                    });
                }
            })
        }
    }


    toggleScanning(e) {
        let val = e?.currentTarget?.dataset?.val;
        val = val && JSON.parse(val);

        this.setState((prevState) => {
            if (val?.id) {
                return {
                    scanning: !prevState.scanning, currentBarcodeQuestion: { name: val?.id }
                }
            }
            return { scanning: !prevState.scanning };
        })
    }

    setbarcodeimg(img) {
        this.setState((prevState) => {
            return { barcodeimg: img };
        })
    }

    getScannerResult(result) {
        const { name: questName } = this.state.currentBarcodeQuestion;
        if (result) {
            this.setState({ scanning: false });
            this.onChange(null, { barcode: { value: result, name: questName } });
        }

    }
    getImageofFailedScan(file) {
        let failedImg = this.getFilesAsUrl([file]);
        this.setbarcodeimg(failedImg[0]);
        const { name: questName } = this.state.currentBarcodeQuestion;
        const { dispatch, fileToUpload } = this.props;
        const newFilesToUpload = this.processFileToSave([file], questName, fileToUpload, true);
        dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: newFilesToUpload });
        this.onChange(null, { barcode: { name: questName, files: [file], value: "" } });
    }


    getSubmittedSurveyData = async () => {
        const { match, history, dispatch } = this.props;
        const { params } = match;
        let param = {
            storeName: apis.storeName.REQ_LoadSurveyById + params.SurveyId,
            action: 'load',
            id: params.SurveyId
        };
        //Get Survey detail
        const response = await request({
            url: apis.Survey,
            params: param,
            history, isOfflineModeEnable: false, defaultData: {
                success: true,
                data: {
                    SurveyData: []
                }
            }
        });
        if (response && response.success) {
            const answerDataTemp = response.data.SurveyData ? JSON.parse(response.data.SurveyData) : []
            const answerData = []
            for (const item of answerDataTemp) {
                let obj = {
                    attachment: item.attachment ? item.attachment.indexOf(',') > -1 ? item.attachment.split(',') : [item.attachment] : '',
                    childName: undefined,
                    ids: [],
                    name: item.questionId,
                    questionNumber: item.questionId,
                    value: item.answer,
                }
                answerData.push(obj)
            }
            this.setState({ readOnlyView: true, repeatCount: response.data?.RepeatCount })
            dispatch({ type: actions.SET_ANSWER_DATA, answerData: answerData });
        }
    }

    importSurveyFile = async (surveyTypeId) => {
        const { history } = this.props;
        const surveyTypeData = await dbUtil.get("surveyType") || {};
        let errorMessage = "";
        if (Object.entries(surveyTypeData).length === 0 || !surveyTypeData.recordCount) {
            errorMessage = "No Survey assigned to this client.";
        } else {
            const surveyTypeIndex = surveyTypeData.records.findIndex(e => e.SurveyTypeId === Number(surveyTypeId));
            if (surveyTypeIndex < 0) {
                errorMessage = "No questions found for the selected Survey.";
            } else {
                const { SurveyQuestions } = surveyTypeData.records[surveyTypeIndex];
                if (SurveyQuestions) {
                    return JSON.parse(SurveyQuestions);
                } else {
                    errorMessage = "No questions found for the selected Survey.";
                }
            }
        }

        swal({
            title: errorMessage,
            icon: 'error',
        })
        history.push("/Location");
        return false;

    }
    mergeProducts = (parentProducts, childProducts) => {
        // if two same product are on planogram it will return the single entry 
        const mergedProducts = [...parentProducts, ...childProducts];
        const uniqueProducts = Array.from(new Set(mergedProducts.map(JSON.stringify)), JSON.parse);
        return uniqueProducts;
    }
    getParentAssetsWithCombinedProducts = (assets) => {
        var parentAssets = [];
        // We will show only parent assetInfo, if the product are there then will show combine info  
        for (const asset of assets) {
            if (asset.ParentAssetId > 0) {
                const parentAsset = assets.find(a => a.AssetId === asset.ParentAssetId);
                if (parentAsset) {
                    if (parentAsset.Products && asset.Products) {
                        const parentProducts = Utils.isJson(parentAsset.Products)
                            ? JSON.parse(parentAsset.Products)
                            : Utils.isJson(parentAsset.Products + '"}]')
                                ? JSON.parse(parentAsset.Products + '"}]')
                                : parentAsset.Products;
                        const childProducts = Utils.isJson(asset.Products)
                            ? JSON.parse(asset.Products)
                            : Utils.isJson(asset.Products + '"}]')
                                ? JSON.parse(asset.Products + '"}]')
                                : asset.Products;
                        parentAsset.Products = JSON.stringify(this.mergeProducts(parentProducts, childProducts));
                    }
                    // if the entry already there need to remove and add the new entry 
                    parentAssets = parentAssets.filter(item => item.AssetId !== parentAsset.AssetId);
                    parentAssets.push(parentAsset);

                }
            } else {
                var index = parentAssets.findIndex((obj) => { return obj.AssetId === asset.AssetId });
                if (index < 0) {
                    parentAssets.push(asset);
                }
            }
        }

        return parentAssets;
    }


    updateQuestionJson = async (surveyTypeId) => {
        const { match } = this.props;
        const { params } = match;
        let surveyJson = [];
        surveyJson = await this.importSurveyFile(surveyTypeId || params.SurveyTypeId);
        if (!surveyJson) {
            return false;
        }

        surveyJson = addRepeatedQuestions(surveyJson, this.state.repeatCount, params.SurveyId); // add repeated questions if any

        let { typeQuestion } = this.state,
            typeObj = [...typeQuestion];
        const datajson = [];
        const survey = JSON.stringify(surveyJson);
        const newSurvey = JSON.parse(survey);
        const questionByAsset = {};
        for (const section of [...newSurvey.sections]) {
            if (typeof section.items === 'string') {
                const records = this.state[section.items];
                if (records?.records?.length) {
                    let data = this.getParentAssetsWithCombinedProducts(records.records);
                    records.records = data;
                }
                section.items = records?.records || [];

            }
            const origSection = JSON.stringify(section);

            for (const item of [...section.items]) {
                const newSection = JSON.parse(origSection);
                newSection.title = template.replaceTags(newSection.title, item);
                newSection.hintImage = newSection.hintImage ? Utils.loadPlanogramImage(item.AssetId) : false;
                newSection.planogramName = item.PlanogramName || '';
                let sectionId = newSection.id;
                newSection.id = `${newSection.id}~${item.RowNumber}`;
                questionByAsset[newSection.id] = [];
                for (const q of newSection.questions) {
                    q.text = template.replaceTags(q.text, item);
                    q.id = template.replaceTags(q.id, { section: newSection, item });
                    q.name = `${sectionId}.${q.id}${item ? ("~" + item.RowNumber) : ''}`;
                    q.hintImage = q.hintImage ? Utils.loadPlanogramImage(item.AssetId) : false;
                    q.planogramName = item.PlanogramName || '';
                    q.SerialNumber = item.SerialNumber
                    if (params.SurveyId) {
                        delete q.defaultDisplay;
                    }
                    const { dynamicOptions } = q;
                    if (dynamicOptions) {
                        const qOptions = [];
                        const qOptionItems = Utils.isJson(item[dynamicOptions.items])
                            ? JSON.parse(item[dynamicOptions.items])
                            : Utils.isJson(item[dynamicOptions.items] + '"}]')
                                ? JSON.parse(item[dynamicOptions.items] + '"}]')
                                : item[dynamicOptions.items];

                        const optionLayout = JSON.stringify(dynamicOptions.layout);
                        if (qOptionItems) {
                            for (const qOptionItem of qOptionItems) {
                                const qOption = JSON.parse(optionLayout);
                                qOption.key = template.replaceTags(qOption.key, qOptionItem);
                                qOption.value = template.replaceTags(qOption.value, qOptionItem);
                                qOptions.push(qOption);
                            }
                        }
                        q.options = qOptions;
                    }
                    if (q.dependentQuestion) {
                        for (const dependent of q.dependentQuestion) {
                            dependent.question = template.replaceTags(dependent.question, { section: newSection, item });
                        }
                    }
                    questionByAsset[newSection.id].push(q)
                }
                datajson.push(newSection);
            }

        }

        for (const section of datajson) {
            typeObj.push({ title: section.title, id: section.id, hintImage: section.hintImage, planogramName: section.planogramName });
        }
        this.setState({ typeQuestion: typeObj, data: datajson, questionByAsset: questionByAsset }, () => this.sectionLoaded());
    }

    getLocationDetail = async () => {
        const { match, history, dispatch } = this.props;
        const isOnline = navigator.onLine;
        const { params } = match;
        let selectedResult = {};
        if (Number(params.LocationId)) {
            let param = {
                storeName: apis.storeName.REQ_SurveyDetails + params.LocationId,
                action: 'load',
                locationId: params.LocationId,
                includeAsset: true
            };
            const response = await request({
                url: apis.SurveyDetails,
                params: param,
                history,
                withBar: dispatch,
                isOfflineModeEnable: true,
                defaultData: {
                    Results: []
                }
            });
            if (response && response.Results && response.Results.length) {
                selectedResult = response.Results[0];
            }
        } else {
            const locationData = await dbUtil.get(apis.storeName.REQ_LocationList) || {};
            let selectedLocation = locationData.records.filter(e => e.LocationId === Number(params.LocationId)) || [];
            if (selectedLocation.length) {
                selectedResult = selectedLocation[0];
            }
        }
        if (Object.keys(selectedResult).length > 0) {
            this.setState({
                locationData: selectedResult,
                assets: { records: selectedResult.AssetList ? JSON.parse(selectedResult.AssetList) : [] },
                promotions: { records: selectedResult.PromotionList ? JSON.parse(selectedResult.PromotionList) : [] }
            });
        }
        if (isOnline) {
            await Utils.getSurveyTypeData(history);
        }
        this.updateQuestionJson(params.SurveyTypeId);
    }

    setSkipQuestion = (data) => {
        let dependencyArray = [...this.state.dependencyQuestions],
            { answerData, dispatch } = this.props;
        data.length > 0 && data.map((val, i) => {
            val.questions.length > 0 && val.questions.map((question, k) => {
                if (question.dependentQuestion) {
                    let index = answerData.findIndex(x => x.name === question.dependentQuestion.question && question.dependentQuestion.answerkey.includes(Number(x.key)));
                    if (index > -1) {
                        dependencyArray.push({ question: question.dependentQuestion.question, answerkey: Number(answerData[index].key) });
                    }
                }
            })
        })
        dispatch({ type: actions.SET_DEPENDDENCY_QUESTION, dependencyQuestions: dependencyArray });
    }

    scrollInnerArea(answer, index) {
        this.setState({ activeType: index });
    }

    /**
     * scroll content on click scroll button top/bottom
     */
    scrollContent(e) {
        let name = e.target.getAttribute('id');
        const scrollDiv = document.getElementById('inner-area');
        let direction = name === "top-scroller-icon" ? -scrollDiv.scrollHeight : scrollDiv.scrollHeight;
        if (scrollDiv) {
            if (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
                scrollDiv.scrollTop = direction;
            }
            else {
                scrollDiv.scroll({ top: direction, left: 0, behavior: 'smooth' });
            }
        }
    }

    typeQuestionClick(name, index) {
        this.setState({ enableScroll: false });
        document.getElementById(name).scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        this.setState({ activeType: index });
        setTimeout(() => this.setState({ enableScroll: true }), 3000);
        return false;
    }

    clearAllFileInputs() {
        var fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach(function (fileInput) {
            fileInput.value = '';
        });
    }
    onChange = async (event, additionalParams) => {
        const { dispatch, answerData, captureMatrixClone, scenePicturesTakenClone, scenePicturesTaken: picturesTaken, captureSceneQuestionIdsClone, captureSceneSerialNumbersClone, captureSceneSerialNumbers: serialNumbers, fileToUpload, imageSequence } = this.props;
        let name, checked, type, value, dataset, files;
        let isFile, isMultiFile, matrix = null, barcode = null, datePicker, invalidNumber = false;

        if (event?.target) {
            name = event.target.name;
            checked = event.target.checked;
            type = event.target.type;
            value = event.target.value;
            dataset = event.target.dataset;
            files = event.target.files;
        }

        if (additionalParams) {
            isFile = additionalParams.isFile;
            isMultiFile = additionalParams.isMultiFile;
            matrix = additionalParams.matrix;
            barcode = additionalParams.barcode;
            datePicker = additionalParams.datePicker;
        }

        /******* Start Check Dependency *****/
        let { data } = this.state;
        dataset = { ...dataset, ...barcode?.dataset }
        let answerArray = [...answerData];
        let ids = dataset && !this.isEmpty(dataset.ids) ? dataset.ids.split(",") : [];
        let dependencyArray = [...this.props.dependencyQuestions];

        //checking if repeated question answered
        //can not convert boolean string to boolean so I keep the condition: dataset.repeated === 'true' as it is
        if (dataset.repeated === 'true' && dataset?.answerkey && dataset?.repeatkey) {
            if (dataset?.answerkey === dataset?.repeatkey) {
                this.setState((prevState) => {
                    const newCount = Number(prevState.repeatCount) + 1;
                    if (prevState.repeatedIds.includes(name)) {
                        return prevState;
                    }
                    return { ...prevState, repeatCount: newCount, repeatedIds: [...prevState.repeatedIds, name] }
                })
            }
        }

        data.length > 0 && data.map((val, i) => {

            val.questions.length > 0 && val.questions.map((question, k) => {
                if (question.dependentQuestion && dataset.dependency) {
                    let answerIndex = answerArray.findIndex(x => x.name === question.name && x.ids.includes(dataset.answerkey));
                    if (answerIndex > -1) {
                        answerArray.splice(answerIndex, 1);
                    }
                }
            })

        })

        //TODO: change the name dependencyArray and dependencyQuestions to 'answers_of_questions_with_dependency
        //TODO: change the property 'question' in Object dependencyArray to 'questionName'
        const callbackCondition = (dependency) => {
            if (dependency.question === name) {
                if (type === AssessmentType.Checkbox && dependency.answerkey !== Number(dataset.answerkey)) {
                    return false;
                } else {
                    return true;
                }

            }
        }

        let dependencIndex = dependencyArray.findIndex(callbackCondition);
        if (dependencIndex > -1) {
            dependencyArray.splice(dependencIndex, 1);
        }
        if (dataset && dataset.dependency) {
            if (!(type === AssessmentType.Checkbox && !checked)) {
                dependencyArray.push({ question: name, answerkey: Number(dataset.answerkey) });
            }
        }

        /*******End Check Dependency *****/

        if (files && !dataset.multifilestitch && !dataset.matrix && dataset.questionnumber && !barcode) {
            const newFilesToUpload = this.processFileToSave(files, dataset.questionnumber, fileToUpload);
            dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: newFilesToUpload });
            files = this.getFilesAsUrl(files);
        }

        /* handle capture scene onChange event*/
        if (dataset && (dataset.matrix || dataset.multifilestitch)) {
            let scenePicturesTaken, captureSceneQuestionIds, captureSceneSerialNumbers, questNum;
            type = dataset.type;
            value = "null";
            if (dataset.imagecount) {
                questNum = dataset.questionnumber.split("|")[0];
                name = questNum;
                let newPictures = Object.assign({}, picturesTaken);
                let newSerialNumber = Object.assign({}, serialNumbers);
                if (newPictures[questNum]) {
                    newPictures[questNum].push({ file: files[0], gridIndex: dataset?.sequence })
                } else {
                    newPictures[questNum] = [{ file: files[0], gridIndex: dataset?.sequence }]
                }
                scenePicturesTaken = newPictures;
                captureSceneQuestionIds = questNum;
                newSerialNumber[questNum] = dataset.serialnumber;
                captureSceneSerialNumbers = newSerialNumber;
                files = this.getFilesAsUrl(files);

                let newSequence = Object.assign({}, imageSequence);
                if (newSequence[questNum] && newSequence[questNum].findIndex(sequence => sequence === dataset?.sequence) === -1) {
                    newSequence[questNum].push(Number(dataset?.sequence));
                } else {
                    newSequence[questNum] = [Number(dataset?.sequence)];
                }
                dispatch({ type: actions.SET_IMAGE_SEQUENCE, imageSequence: newSequence });
            } else {
                dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
                this.setState({ showPictureCapture: false });
                questNum = dataset.scenecaptureid;
                const currentMatrix = matrix[questNum];
                if (currentMatrix?.length < 0) {
                    return;
                }
                name = dataset.name;
                let gridPictures = [];
                currentMatrix.map(({ file }) => {
                    gridPictures.push(file);
                })
                files = this.getFilesAsUrl(gridPictures);
                scenePicturesTaken = { ...scenePicturesTakenClone };
                captureSceneQuestionIds = [...captureSceneQuestionIdsClone];
                captureSceneSerialNumbers = { ...captureSceneSerialNumbersClone };
                dispatch({ type: actions.SET_CAPTURE_MATRIX, captureMatrix: JSON.parse(JSON.stringify(captureMatrixClone)) });

            }
            dispatch({ type: actions.SET_SCENE_PICTURES, scenePicturesTaken: scenePicturesTaken });
            dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS, captureSceneQuestionIds: captureSceneQuestionIds })
            dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS, captureSceneSerialNumbers: captureSceneSerialNumbers });
            this.setState({ enableScroll: false });

        }

        /*handle barcode onchange event*/
        if (barcode) {
            value = barcode.value;
            name = barcode.name;
            type = AssessmentType.barcode;
            dataset.questionnumber = barcode.name;
            if (value) {
                dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: {} });
                files = null;
                isFile = false;
            } else {
                isFile = true;
                files = barcode.files;
            }

        }

        /*handle datepicker onchange event*/
        if (datePicker) {
            value = datePicker.value;
            name = datePicker.questionnumber;
            type = AssessmentType.date;
            dataset.actualquestion = datePicker.actualquestion;
            dataset.questionnumber = datePicker.questionnumber;
        }

        /*handle number onchange event*/
        // with input type=number the char 'e' get printed without no option to filter
        if (dataset.type === AssessmentType.Number) {
            invalidNumber = isNaN(value);
            if (!invalidNumber && value !== "") {
                const convertedNumber = Number(value), minBound = Number(dataset.min), maxBound = Number(dataset.max);
                if (!((minBound <= convertedNumber) && (convertedNumber <= maxBound))) {
                    invalidNumber = true;
                    this.setState({ alertMessage: `Value must be between ${minBound} and ${maxBound}`, openAlert: true });
                }
            }
        }

        let valIndex = answerArray.findIndex(x => x.name === name);
        if (valIndex > -1) {
            if ((type === AssessmentType.Checkbox && !checked) || (this.isEmpty(value) && !barcode && dataset.answerkey)) {
                const optionIndex = answerArray[valIndex].optionId.findIndex(x => x === dataset.answerkey);
                answerArray[valIndex]?.optionId.splice(optionIndex, 1);
                answerArray[valIndex].value = answerArray[valIndex]?.optionId?.join(",");
                if (answerArray[valIndex]?.optionId?.length === 0) {
                    answerArray.splice(valIndex, 1);
                }
            } else {
                let fileArray = [];
                if (isMultiFile) {
                    if (!answerArray[valIndex].attachment?.length) {
                        answerArray[valIndex].attachment = files;
                    } else {
                        fileArray = [...answerArray[valIndex].attachment];
                        fileArray.push(files[0]);
                        answerArray[valIndex].attachment = fileArray;
                    }

                } else if (isFile && files?.length) {
                    answerArray[valIndex].attachment = files;
                } else {
                    answerArray[valIndex].attachment = null;
                }
                if (!invalidNumber) {
                    answerArray[valIndex].value = value;
                }

                answerArray[valIndex].key = dataset.answerkey;
                answerArray[valIndex].ids = ids;
                answerArray[valIndex].childName = dataset.childname;
                answerArray[valIndex].questionAndAnswer = dataset.actualquestion;
                answerArray[valIndex].questionNumber = dataset.questionnumber;
                answerArray[valIndex].type = type;
                answerArray[valIndex].repeated = dataset.repeated;
                answerArray[valIndex].optionId = [...answerArray[valIndex].optionId, Number(dataset.answerkey)];
                if (type === AssessmentType.Checkbox) {
                    answerArray[valIndex].value = answerArray[valIndex]?.optionId?.join(",");
                }
                if (dataset.imagecount) {
                    const newFiles = { ...answerArray[valIndex].attachmentByLabel, [dataset.questionnumber]: files };
                    answerArray[valIndex].attachmentByLabel = newFiles;
                    answerArray[valIndex].questionNumber = dataset.questionnumber.split("|")[0];
                }
            }
        }
        else {
            const answerObj = {
                name: name,
                attachment: files?.length > 0 ? files : null,
                value: invalidNumber ? '' : value,
                key: dataset.answerkey,
                ids: ids,
                childName: dataset.childname,
                questionAndAnswer: dataset.actualquestion,
                questionNumber: dataset.questionnumber,
                type: type,
                repeated: dataset.repeated,
                optionId: [Number(dataset.answerkey)]
            }
            if (type === AssessmentType.Checkbox) {
                answerObj.value = answerObj?.optionId?.join(",");
            }
            if (dataset.imagecount) {
                answerObj.attachmentByLabel = { [dataset.questionnumber]: files };
                answerObj.questionNumber = dataset.questionnumber.split("|")[0];
            }
            answerArray.push(answerObj);
        }
        this.clearAllFileInputs();
        dispatch({ type: actions.SET_DEPENDDENCY_QUESTION, dependencyQuestions: dependencyArray });
        dispatch({ type: actions.SET_ANSWER_DATA, answerData: answerArray });
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
    }

    getFilesAsUrl = (files) => {

        if (files) {
            const filesArray = Array.from(files).map((file) =>
                URL.createObjectURL(file)
            );
            Array.from(files).map(
                (file) => URL.revokeObjectURL(file) // avoid memory leak
            );
            return filesArray;
        }
    }

    processFileToSave = (files, questionId, imageStore, isBarcode = false) => {
        if (isBarcode) {
            imageStore[questionId] = [...files];
        } else {
            imageStore[questionId] = imageStore[questionId] ? [...imageStore[questionId], ...files] : [...files];
        }
        return imageStore;
    }

    getDependency = (val) => {
        let dependencyArray = this.props.dependencyQuestions,
            { dependentQuestion } = val,
            canDisable = false;

        if (dependencyArray.length && dependentQuestion) {
            dependentQuestion.forEach(function (item, key) {
                if (!canDisable) {
                    let index = dependencyArray.findIndex(x => x.question === item.question && item.answerkey.includes(Number(x.answerkey)));
                    canDisable = index > -1 ? dependentQuestion[key].behaviour : false;
                }
            })
        }
        return canDisable;
    }
    onRadioDblClick(event) {
        let { name } = event.target;
        const { answerData, dispatch } = this.props;
        if (event.target.checked) {
            event.target.checked = false;
            let answerArray = [...answerData],
                valIndex = answerArray.findIndex(x => x.name === name);
            if (valIndex > -1) {
                answerArray.splice(valIndex, 1); // removing the object from Answer array where we have double clicked. 
            }
            dispatch({ type: actions.SET_ANSWER_DATA, answerData: answerArray });
        }

    }
    /**
     * @Ticket #18378 Reassessment show last answered when the assessment is Comprehensive Assessment.
     */
    updateLastResponseText(allAnswers, lastResponseText) {
        allAnswers = allAnswers.sort(function (a, b) {
            return a.key - b.key;
        });
        if (allAnswers.length > 0) {
            let childItem = null;
            allAnswers.forEach((item) => {
                if (item.childName) {
                    childItem = item.childName;
                }
                if (lastResponseText === null) {
                    lastResponseText = 'Last Response : ' + item.value;
                }
                else {
                    if (childItem === item.name) {
                        lastResponseText += ' | Comment-' + item.value;
                    }
                    else {
                        lastResponseText += ' ; ' + item.value;
                    }

                }
            });
        }
        return lastResponseText;
    }

    handleOpenCaptureScene(e) {
        const { readOnlyView } = this.state;
        if (readOnlyView) {
            return;
        }
        try {
            let val
            val = JSON.parse(e.currentTarget.dataset?.val);
            const index = val.id;
            const { dispatch, captureMatrix, scenePicturesTaken, captureSceneQuestionIds, captureSceneSerialNumbers } = this.props;

            //Note: using JSON.parse(JSON.stringify()) to clear the deep reference to the object.
            dispatch({ type: actions.SET_CAPTURE_MATRIX_CLONE, captureMatrixClone: JSON.parse(JSON.stringify(captureMatrix)) });
            dispatch({ type: actions.SET_SCENE_PICTURES_CLONE, scenePicturesTakenClone: scenePicturesTaken });
            dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE, captureSceneQuestionIdsClone: JSON.parse(JSON.stringify(captureSceneQuestionIds)) });
            dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE, captureSceneSerialNumbersClone: JSON.parse(JSON.stringify(captureSceneSerialNumbers)) });

            this.setState({ showPictureCapture: !this.state.showPictureCapture, captureQuestName: val.id, captureQuestType: val.type, captureActQuest: val.text, captureQuestNum: val.id, captureSceneIndex: index, currentCaptureSerialNumber: val.SerialNumber });
        } catch (err) {
            console.error(err);
        }


    }
    convertStringToDate = (value) => {
        return dayjs(value).isValid() ? dayjs(value).utc().local().format("MM/DD/YYYY").toString() : value;
    }
    //TODO: change val into question and remove valQuestion
    //TODO: remove parameter count
    //TODO: change parameter list into object
    //TODO: move renderAnswer to a different component
    renderAnswer = (val, index, isDisableAll, valQuestion, dependencyStatus, count) => {
        const { assessmentDetail, answerData } = this.props;
        const { readOnlyView } = this.state
        let lastResponseText = null;
        if (assessmentDetail && assessmentDetail.data && assessmentDetail.data.data && assessmentDetail.data.data.detail && assessmentDetail.data.data.detail.AssessmentData) {
            var allAnswers = JSON.parse(assessmentDetail.data.data.detail.AssessmentData);
            allAnswers = allAnswers.filter(function (currentAnswer) { return currentAnswer.questionAndAnswer === valQuestion });
            lastResponseText = this.updateLastResponseText(allAnswers, lastResponseText);
        }

        var inputProps = { disabled: isDisableAll ? true : dependencyStatus ? true : false };
        let answerArray = answerData;
        let valOutside = answerArray.findIndex(x => x.name === val.id);
        let ids = (val.dependentQuestion && val.dependentQuestion.answerkey) || [];
        let imageItem = ((allowedFileTypes.includes(val.type) || val.isFile) && answerArray[valOutside] && answerArray[valOutside].attachment) ? [...answerArray[valOutside].attachment] : [];
        if (val.imageCount && answerArray[valOutside]) {
            imageItem = answerArray[valOutside]?.attachmentByLabel;
        }

        return (val.type === AssessmentType.Select ?
            <div key={index}>
                <Grid item>
                    <Box check>

                        <select
                            className="drop-down"
                            name={val.id}
                            value={(valOutside > -1) ? answerArray[valOutside].value : ""}
                            data-required={val.required}
                            {...inputProps}
                            data-ids={ids}
                            data-actualquestion={val.text}
                            data-questionnumber={val.id}
                            data-repeated={val.repeated || false}
                            data-repeatkey={val.repeatkey}
                            disabled={readOnlyView}
                            onChange={this.onChange}
                        >
                            {val.options.map((option, i) => {
                                return <option value={option.value} key={i}>{option.value}</option>
                            })}
                        </select>
                    </Box>
                </Grid>
                <div className="clearfix"></div>
            </div> :
            <Grid key={index} className="survey-questions">
                {lastResponseText ? <div>{lastResponseText} </div> : null}
                {val.options && val.options.length > 0 ? val.options.map((ans, i) => {

                    let valIndex = answerArray.findIndex((x) => {
                        let xName = x.name;
                        if (AssessmentType.Checkbox === val.type) {
                            return xName === val.id;
                        }
                        if (val.type === 'text') {
                            return xName === ans.name
                        }
                        return x.value === ans.value && xName === val.id
                    });


                    let conditionBasedOnType;
                    if (ans.type === 'radiotext' || val.type === AssessmentType.Radio) {
                        conditionBasedOnType = answerArray[valIndex]?.value === ans.value;
                    } else if (ans.type === 'checktext' || val.type === AssessmentType.Checkbox) {
                        if (readOnlyView) {
                            conditionBasedOnType = answerArray[valIndex]?.value?.split(",")?.includes(String(ans.key));
                        } else {
                            conditionBasedOnType = answerArray[valIndex]?.optionId?.includes(Number(ans.key));
                        }

                    }
                    switch (ans.type) {
                        case "radiotext":
                        case "checktext":
                            let childQuestionValueIndex = -1;
                            if (valIndex > -1 && answerArray[valIndex].childName) {
                                childQuestionValueIndex = answerArray.findIndex((x) => {
                                    return x.name === answerArray[valIndex].childName
                                });
                            }
                            return <Box key={i} check>
                                <label check className={(readOnlyView || dependencyStatus) && "label-disable-color"} >
                                    <input
                                        name={val.id}
                                        value={ans.value}

                                        type={val.type}
                                        onChange={this.onChange}
                                        checked={(valIndex > -1) && (conditionBasedOnType) ? true : false}
                                        data-required={val.required}
                                        {...inputProps}
                                        data-answerkey={ans.key}
                                        data-dependency={ans.dependency}
                                        data-ids={ids}
                                        data-childname={ans.id}
                                        className={val.type === AssessmentType.Radio ? "radioButton" : ""}
                                        data-actualquestion={val.text}
                                        data-questionnumber={val.id}
                                        data-repeated={val.repeated || false}
                                        data-repeatkey={val.repeatkey}
                                        data-optionid={val.type === AssessmentType.Radio ? val.id : val.id + "_" + i}
                                        disabled={readOnlyView || dependencyStatus}
                                    />
                                    {ans.value}
                                </label>
                                {valIndex > -1 && answerArray.length > 0 && answerArray[valIndex].childName &&
                                    <input
                                        type="textarea"
                                        name={ans.name}
                                        className="assessment-textarea"
                                        placeholder='Type Here...'
                                        onChange={this.onChange} {...inputProps}
                                        data-ids={ids}
                                        autoComplete="off"
                                        value={childQuestionValueIndex > -1 ? answerArray[childQuestionValueIndex].value : ""}
                                        data-answerkey={ans.key}
                                        data-actualquestion={val.text}
                                        data-questionnumber={val.id}
                                        data-repeated={val.repeated || false}
                                        data-repeatkey={val.repeatkey}
                                        disabled={readOnlyView || dependencyStatus}
                                    />
                                }
                            </Box>
                        case "text":
                            return <div key={index}>
                                <Grid sm={12} md={12} lg={12}>
                                    <input
                                        type="textarea"
                                        name={ans.name}
                                        className="assessment-textarea"
                                        placeholder={ans.placeholder || 'Type Here...'}
                                        onChange={this.onChange} {...inputProps}
                                        autoComplete="off"
                                        data-ids={ids}
                                        value={valIndex > -1 ? answerArray[valIndex].value : ""}
                                        data-answerkey={ans.key}
                                        data-actualquestion={val.text}
                                        data-questionnumber={val.id}
                                        data-repeated={val.repeated || false}
                                        data-repeatkey={val.repeatkey}
                                        disabled={readOnlyView || dependencyStatus}
                                    />
                                </Grid>
                                <div className="clearfix"></div>
                            </div>
                        default:
                            break;
                    }
                    switch (val.type) {
                        case AssessmentType.Radio:
                        case AssessmentType.Checkbox:
                            return <><Box key={i} check >
                                <label className={(readOnlyView || dependencyStatus) && "label-disable-color"}>
                                    <input
                                        name={val.id}
                                        value={ans.value}
                                        type={val.type}
                                        onChange={this.onChange}
                                        checked={(valIndex > -1) && (conditionBasedOnType) ? true : false}
                                        data-required={val.required}
                                        {...inputProps}
                                        data-answerkey={ans.key}
                                        data-dependency={ans.dependency}
                                        data-ids={ids}
                                        className={val.type === AssessmentType.Radio ? "radioButton" : ""}
                                        data-actualquestion={val.text}
                                        data-questionnumber={val.id}
                                        data-repeated={val.repeated || false}
                                        data-repeatkey={val.repeatkey}
                                        data-optionid={val.type === AssessmentType.Radio ? val.id : val.id + "_" + i}
                                        disabled={readOnlyView || dependencyStatus}
                                    />
                                    {ans.value}
                                </label>
                            </Box>
                                {(!readOnlyView && ans.isFile) && <div className="file-center mb-2">
                                    <div className="input--file">
                                        <FaCamera color="#3f51b5" size="24" className={!((valIndex > -1) && answerArray[valIndex].value === ans.value) && ' disbale-img'} /><label className={`ml-2 ${(readOnlyView || dependencyStatus) && "label-disable-color"}`} > Take Picture</label>
                                        <input type="file" accept="image/*"
                                            key={val.key} name={val.id}
                                            data-actualquestion={val.text}
                                            data-questionnumber={val.id}
                                            data-repeated={val.repeated || false}
                                            data-repeatkey={val.repeatkey}
                                            capture="environment" id="fileUpload" alt="Choose image" onChange={(e) => this.onChange(e, { isFile: true })} {...inputProps} disabled={readOnlyView || !((valIndex > -1) && answerArray[valIndex].value === ans.value)} />
                                    </div>
                                    {imageItem &&
                                        imageItem.map(item => {
                                            return (
                                                <div className="image-container" key={item.name}>
                                                    <canvas className="object-fit-image" id={`image-view${item.name}`} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>}
                                {
                                    (readOnlyView && ans.isFile) && <div className="image-container">
                                        <img alt="preview" src={`${apis.Thumbnail}?imagePath=${(valOutside > -1) ? answerArray[valOutside].attachment : ""}&isLocalImage=true&isShowAttachmentRecord=true`} className="survey-view-image" />
                                    </div>
                                }
                            </>
                        default:
                            return;
                    }

                }) :
                    (val.type === AssessmentType.File) ? (<div className="file-center">
                        <div className="input--file">
                            <FaCamera color="#3f51b5" size="24" className={dependencyStatus && 'disbale-img'} /><label className={`${(readOnlyView || dependencyStatus) && "label-disable-color"}`}> Take Picture</label>
                            <input type="file" accept="image/*"
                                key={val.key} name={val.id}
                                data-actualquestion={val.text}
                                data-questionnumber={val.id}
                                data-repeated={val.repeated || false}
                                data-repeatkey={val.repeatkey}
                                capture="environment" id="fileUpload" alt="Choose image" onChange={(e) => this.onChange(e, { isFile: true })} {...inputProps} disabled={readOnlyView || dependencyStatus} />
                        </div>

                        {(!readOnlyView && imageItem) &&
                            imageItem.map((item, imageIndex) => {
                                return (
                                    <div className="image-container" key={imageIndex} onClick={() => this.showPopupImageClick(imageIndex, val.id)}>
                                        <img src={item} alt="" />
                                    </div>
                                )
                            })
                        }
                        {
                            readOnlyView && <div className="image-container">
                                <img alt="preview" src={`${apis.Thumbnail}?imagePath=${(valOutside > -1) ? answerArray[valOutside].attachment : ""}&isLocalImage=true&isShowAttachmentRecord=true`} className="survey-view-image" />
                            </div>
                        }
                    </div>) :
                        val.type === AssessmentType.MultiFile ? (<div>
                            <div className="input--file">
                                <FaCamera color="#3f51b5" size="24" className={dependencyStatus && 'disbale-img'} /><label className={`${(readOnlyView || dependencyStatus) && "label-disable-color"}`}> Take Pictures</label>
                                <input type="file" multiple accept="image/*"
                                    key={val.key} name={val.id}
                                    data-actualquestion={val.text}
                                    data-questionnumber={val.id}
                                    data-repeated={val.repeated || false}
                                    data-repeatkey={val.repeatkey}
                                    capture="environment" id="multiFileUpload" alt="Choose image" onChange={(e) => this.onChange(e, { isFile: true, isMultiFile: true })} disabled={readOnlyView || dependencyStatus} />
                            </div>
                            {(!readOnlyView && imageItem) &&
                                imageItem.map((item, imageIndex) => {
                                    return (
                                        <div className="image-container" key={imageIndex} onClick={() => this.showPopupImageClick(imageIndex, val.id)}>
                                            <img src={item} alt="" />
                                        </div>
                                    )
                                })
                            }
                            {
                                (readOnlyView && (valOutside > -1) && answerArray[valOutside].attachment) && answerArray[valOutside].attachment.map(imageId => {
                                    return (<div className="image-container" key={imageId} >
                                        <img alt="preview" src={`${apis.Thumbnail}?imagePath=${imageId}&isLocalImage=true&isShowAttachmentRecord=true`} className="survey-view-image" />
                                    </div>
                                    )
                                })
                            }
                        </div>) :
                            //capture scene start
                            (val.type === AssessmentType.Scene || val.type === AssessmentType.MultiFileStitch) ? (<div>
                                {val.imageCount && !readOnlyView ?
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-between',

                                        }}
                                    >
                                        {val?.imageLabels.map((item, index) => {
                                            return <div className="imageCountWraper">
                                                <div className="input--file image-count" key={index}>
                                                    <span data-val={JSON.stringify(val)} >
                                                        <FaCamera color="#3f51b5" size="24" className={dependencyStatus && 'disbale-img'} /><label className={`${(readOnlyView || dependencyStatus) && "label-disable-color"}`}>{item.label}</label>
                                                        <input type="file" multiple accept="image/*"
                                                            key={val.key} name={`${val.id}|${index}`}
                                                            data-actualquestion={val.text}
                                                            data-questionnumber={`${val.id}|${index}`}
                                                            data-serialnumber={val.SerialNumber}
                                                            data-matrix={true} data-type={AssessmentType.Scene}
                                                            data-sequence={item.sequence}
                                                            data-imagecount={val.imageCount}
                                                            data-repeated={val.repeated || false}
                                                            data-repeatkey={val.repeatkey}
                                                            capture="environment" id="multiFileStitch" alt="Choose image" onChange={(e) => this.onChange(e, { isFile: true })} disabled={readOnlyView || dependencyStatus} />
                                                    </span>
                                                </div>
                                                {(!readOnlyView && imageItem[`${val.id}|${index}`]) &&
                                                    imageItem[`${val.id}|${index}`].map((item, imageIndex) => {
                                                        return (
                                                            <div className="image-container" key={imageIndex} onClick={() => this.showPopupImageClick(imageIndex, `${val.id}|${index}`)}>
                                                                <img src={item} alt="" />
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        })}


                                    </Box>

                                    :
                                    <React.Fragment>
                                        <div className="input--file">
                                            <span data-val={JSON.stringify(val)} onClick={this.handleOpenCaptureScene}>
                                                {!readOnlyView && <><FaCamera color="#3f51b5" size="24" className={dependencyStatus && 'disbale-img'} /><label className={`${(readOnlyView || dependencyStatus) && "label-disable-color"}`}> Take Picture</label></>}
                                            </span>
                                        </div>

                                        <div className="scene-capture-container">
                                            {(!readOnlyView && imageItem) &&
                                                imageItem.map(item => {
                                                    return (
                                                        <div className="image-container" key={item.name} onClick={() => this.showPopupImageClick(item.name, val.id, true)}>
                                                            <img src={item} alt="" />
                                                        </div>
                                                    )

                                                })
                                            }
                                            {
                                                (readOnlyView && (valOutside > -1) && answerArray[valOutside].attachment) && answerArray[valOutside].attachment.map(imageId => {
                                                    if (answerArray[valOutside].attachment[0]?.includes('ImagetType'))
                                                        answerArray[valOutside].attachment[0] = answerArray[valOutside].attachment[0].replace("ImagetType", "ImageType");
                                                    if (!answerArray[valOutside].attachment[0]?.includes('width')) {
                                                        answerArray[valOutside].attachment[0] = answerArray[valOutside].attachment[0] + "&width=400";
                                                    }
                                                    return (<div className="image-container " key={imageId} >
                                                        <img alt="scene preview" src={`${answerArray[valOutside].attachment[0]}`} className="survey-view-image" />
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>)
                                //capture scene end
                                :
                                val.type === AssessmentType.Number ?
                                    <div key={index} >
                                        <Grid sm="12">
                                            <input type="text"
                                                name={val.id} placeholder={val.placeholder || 'Type Here...'}
                                                value={(valOutside > -1) ? answerArray[valOutside].value : ""}
                                                onChange={this.onChange} {...inputProps}
                                                data-ids={ids} data-actualquestion={val.text}
                                                data-questionnumber={val.id}
                                                data-repeated={val.repeated || false}
                                                data-repeatkey={val.repeatkey}
                                                data-type="number"
                                                data-min={val.min || 0}
                                                data-max={val.max || 100}
                                                disabled={readOnlyView || dependencyStatus} />
                                        </Grid>
                                        <div className="clearfix"></div>
                                    </div>
                                    //assessmentType=Date -start-
                                    : val.type === AssessmentType.date ? (<div>
                                        {!readOnlyView &&
                                            < LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    defaultValue={dayjs()}
                                                    name={val.id}
                                                    key={val.key}
                                                    disableTime
                                                    onChange={(date) => this.onChange(null, { datePicker: { value: date, ids, actualquestion: val.text, questionnumber: val.id } })}
                                                    max={val.max} min={val.min}
                                                    value={(valOutside > -1) ? answerArray[valOutside].value : null}
                                                    {...inputProps}
                                                    data-ids={ids}
                                                    data-actualquestion={val.text}
                                                    data-questionnumber={val.id}
                                                    data-repeated={val.repeated || false}
                                                    data-repeatkey={val.repeatkey}
                                                    disabled={readOnlyView || dependencyStatus}
                                                />
                                            </LocalizationProvider>}
                                        {readOnlyView && <input type="text" value={(valOutside > -1) ? this.convertStringToDate(answerArray[valOutside].value) : ""} disabled />}
                                    </div>) :
                                        (val.type === AssessmentType.barcode) ?
                                            <div key={index} className="barcode-wrapper">
                                                <BiBarcodeReader color="#3f51b5" size="26" className={(dependencyStatus || readOnlyView) && 'disbale-img'} key={val.name} onClick={this.toggleScanning} data-val={JSON.stringify(val)} />
                                                {this.state.barcodeimg.length === 0 ?
                                                    (<input type="text" readOnly
                                                        name={val.id} value={(valOutside > -1) ? answerArray[valOutside].value : ""}
                                                        {...inputProps}
                                                        data-ids={ids}
                                                        data-actualquestion={val.text}
                                                        data-questionnumber={val.id}
                                                        data-repeated={val.repeated || false}
                                                        data-repeatkey={val.repeatkey}
                                                        disabled={readOnlyView || dependencyStatus}
                                                    />) : null}
                                                {this.state.barcodeimg.length > 0 ? <img alt="barcode preview" src={this.state.barcodeimg} /> : null}
                                                {
                                                    readOnlyView && <div className="image-container">
                                                        <img alt="read only barcode preview" src={`${apis.Thumbnail}?imagePath=${(valOutside > -1) ? answerArray[valOutside].attachment : ""}&isLocalImage=true&isShowAttachmentRecord=true`} className="survey-view-image" />
                                                    </div>
                                                }
                                            </div> :
                                            <div key={index}>
                                                <Grid sm="12">
                                                    <input type="textarea" autoComplete="off"
                                                        name={val.id}
                                                        className="assessment-textarea"
                                                        placeholder={val.placeholder || 'Type Here...'}
                                                        value={(valOutside > -1) ? answerArray[valOutside].value : ""}
                                                        onChange={this.onChange}
                                                        {...inputProps}
                                                        data-ids={ids}
                                                        data-actualquestion={val.text}
                                                        data-questionnumber={val.id}
                                                        data-repeated={val.repeated || false}
                                                        data-repeatkey={val.repeatkey}
                                                        disabled={readOnlyView || dependencyStatus} />
                                                </Grid>
                                                <div className="clearfix"></div>
                                            </div>

                }
            </Grid>
        )
    }

    checkValidation(assetId) {
        let count = 0;
        const { data } = this.state;
        const { answerData, dependencyQuestions } = this.props;
        let requiredList = [];

        data.forEach(function (val) {
            val.questions.forEach(function (question, index) {
                count++;
                let { name, type, text, required, dependentQuestion, defaultDisplay, options, id, imageCount } = question;
                let missingNumber = '';
                let findIndex = answerData.findIndex((x) => {
                    let xName = x.name;
                    let optionIndex = -1;
                    let isNameMatch = false;
                    if (type === "text" || (options && options.length > 0)) {
                        optionIndex = options.findIndex((item) => {
                            return xName === item.name;
                        });
                    }


                    if (imageCount > 0 && xName === id) {
                        const allImages = x.attachmentByLabel;
                        if (imageCount === Object.keys(allImages).length) {
                            return true;
                        } else {
                            required = true;
                            missingNumber = imageCount - Object.keys(allImages).length;
                            return false;
                        }
                    } else if (xName === id) {
                        isNameMatch = true;
                        if (x.type === AssessmentType.Scene || x.type === AssessmentType.MultiFile || x.type === AssessmentType.File) {
                            isNameMatch = x.attachment.length > 0;
                        } else if (x.type === "text") {
                            isNameMatch = x.value.length > 0;
                        }
                    }
                    return optionIndex > -1 || isNameMatch;
                });

                let inactiveQuestion = [];
                if (dependentQuestion) {
                    inactiveQuestion = dependentQuestion.map(function (item) {
                        let isNotActive = false;
                        if (dependencyQuestions.some(x => x.question === item.question && item.answerkey.includes(Number(x.answerkey)))) {
                            isNotActive = item.behaviour !== "show";
                        } else {
                            isNotActive = item.behaviour === "show";
                        }
                        return isNotActive;
                    });
                    inactiveQuestion = inactiveQuestion.filter(e => e !== false);
                    inactiveQuestion = inactiveQuestion.length ? (inactiveQuestion[0] === -1 || inactiveQuestion[0] === true) ? inactiveQuestion : [null] : [null];
                } else {
                    inactiveQuestion = [null]; // Set inactiveQuestion as an array with null if dependentQuestion is not available
                }

                if (!dependencyQuestions.length) {
                    inactiveQuestion = defaultDisplay === "hidden" ? [1] : inactiveQuestion; // Wrap 1 in an array
                }

                if (findIndex === -1 && inactiveQuestion[0] === null && required) {
                    const requireParams = { name: name, text: text, questionNo: count };
                    if (imageCount) {
                        requireParams.message = `Question ${count} has ${missingNumber} missing capture${missingNumber > 1 ? 's' : ''}.`;
                    }
                    requiredList.push(requireParams);
                }
            });
        });

        return requiredList;
    }

    onchanges = (isVisible, text, activeType) => {
        if (isVisible && this.state.enableScroll && this.state.activeType !== activeType) {
            this.setState({ activeType: activeType });
        }
    }
    onSaveSurveyData = async (e) => {
        e.preventDefault();
        this.handleClose();

        const { geoLocationData } = this.props;
        const { mapCoordinates } = geoLocationData
        const { match, dispatch, answerData, fileToUpload } = this.props;
        let me = this;

        const surveyType = await dbUtil.get("surveyType") || {};
        let params = { action: 'save' };
        let { data, locationData, startDate } = me.state,
            allAssessment = data && data;



        let newAnswerData = [], fileData = [];
        for (let index = 0, datalength = allAssessment.length; index < datalength; index++) {
            let question = [...allAssessment[index].questions];
            for (let questionIndex = 0, questionIndexlength = question.length; questionIndex < questionIndexlength; questionIndex++) {
                let oldAnswerData = answerData.find(el => el.questionNumber === question[questionIndex].id);
                if (oldAnswerData) {
                    for (let answerIndex = 0, answerDatalength = answerData.length; answerIndex < answerDatalength; answerIndex++) {

                        if (answerData[answerIndex].questionNumber === question[questionIndex].id) {
                            answerData[answerIndex].required = question[questionIndex].required;

                            if (allowedFileTypes.includes(answerData[answerIndex].type)) {
                                let imageArr, imageName;
                                answerData[answerIndex].value = !answerData[answerIndex].value ? "image" : answerData[answerIndex].value;
                                const questIndex = answerData[answerIndex]["questionNumber"]
                                const selectionType = ['scene', 'multiFileStitch'];
                                const type = answerData[answerIndex].type;

                                if (selectionType.indexOf(type) < 0) {
                                    imageName = '';
                                    imageArr = fileToUpload.hasOwnProperty(questIndex) ? [...fileToUpload[questIndex]] : [];
                                    for (const image of imageArr) {
                                        imageName += `,${image.name}`;
                                        fileData.push(image)
                                    }
                                    imageName = imageName.replace(/^,|,$/g, '');
                                }

                                newAnswerData.push({ questionId: answerData[answerIndex].name, answer: answerData[answerIndex].value, optionId: answerData[answerIndex].optionId, attachment: imageName, type: answerData[answerIndex].type });
                            }
                            else {
                                newAnswerData.push({ questionId: answerData[answerIndex].name, answer: answerData[answerIndex].value, optionId: answerData[answerIndex].optionId, attachment: "" });
                            }
                        }
                    }
                }
                else {
                    newAnswerData.push({
                        questionId: question[questionIndex].id,
                        answer: ""
                    })
                }
            }
        }

        const surveyTypeData = (surveyType.records && surveyType.records.find(x => x.SurveyTypeId === match.params.SurveyTypeId)) || {};

        const submitData = JSON.stringify(newAnswerData);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentTime = dayjs().tz(timezone).format(Utils.DATE_TIME_FORMAT);
        params = Object.assign(params, {
            action: 'save',
            SurveyData: submitData,
            SurveyTypeId: match.params.SurveyTypeId,
            SurveyDateTime: dayjs.utc(startDate).format(Utils.DATE_TIME_FORMAT),
            EndDate: currentTime,
            StartDate: dayjs.utc(startDate).format(Utils.DATE_FORMAT),
            files: fileData,
            LocationId: match.params.LocationId,
            Latitude: mapCoordinates.lat || 0,
            Longitude: mapCoordinates.lng || 0,
            surveyTypeData: surveyTypeData,
            location: locationData.Name,
            RepeatCount: this.state.repeatCount
        })

        await this.saveOfflineData(params);

        dispatch({ type: actions.SET_CAPTURE_MATRIX, captureMatrix: {} });
        dispatch({ type: actions.SET_SCENE_PICTURES, scenePicturesTaken: {} });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS, captureSceneQuestionIds: [] });
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS, captureSceneSerialNumbers: {} });
        dispatch({ type: actions.SET_ANSWER_DATA, answerData: [] });
        dispatch({ type: actions.SET_DEPENDDENCY_QUESTION, dependencyQuestions: [] });
        dispatch({ type: actions.SET_IMAGE_TO_STITCH, imageToStitch: {} });
        dispatch({ type: actions.SET_POSQUESTION, imgToStitchQuestionIds: [] });
        dispatch({ type: actions.SET_POS_SERIAL_NUMBERS, imageSerialNumbers: {} });
        dispatch({ type: actions.SET_CAPTURE_MATRIX_CLONE, captureMatrixClone: {} });
        dispatch({ type: actions.SET_SCENE_PICTURES_CLONE, scenePicturesTakenClone: {} });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE, captureSceneQuestionIdsClone: [] })
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE, captureSceneSerialNumbersClone: {} });
        dispatch({ type: actions.SET_IMAGE_SEQUENCE, imageSequence: {} });
        dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: {} });
        swal({ title: 'Audit Submitted', icon: 'success' });
        me.props.history.push('/Location');

    }


    onSave = (e) => {
        e.preventDefault();
        const { t } = this.props;
        let validArray = this.checkValidation();
        let arrayLength = validArray.length;
        let message = '', isCaptureMissing = false;
        if (arrayLength > 0) {
            message += "Questions ";
            validArray.forEach(function (requiredQuestion, i) {
                if (isCaptureMissing) {
                    return;
                }
                let notLast = (arrayLength - 1 > i) ? ", " : " ";
                if (requiredQuestion.message) {
                    message = requiredQuestion.message;
                    isCaptureMissing = true;
                } else {
                    isCaptureMissing = false;
                    message += requiredQuestion.questionNo + notLast;
                }
            });
            if (!isCaptureMissing) {
                message += "are required to submit the survey";
            }
            this.setState({ openAlert: true, alertMessage: t(message) });
            return false;
        }
        this.setState({ openSurveyDialog: true });
    }

    saveOfflineData = async (params) => {
        const { locationData } = this.state;
        const { captureSceneQuestionIds, scenePicturesTaken, captureSceneSerialNumbers, captureMatrix, imageSequence } = this.props;
        try {
            let previouslySavedParams = await dbUtil.get('Survey') || [];
            params.offlineSceneCaptureData = {
                captureSceneQuestionIds,
                scenePicturesTaken,
                dataCount: Object.keys(scenePicturesTaken).length,
                captureSceneSerialNumbers,
                storeNumber: locationData?.Code,
                captureMatrix,
                imageSequence
            }

            await dbUtil.set('Survey', [...previouslySavedParams, params]);
            return true;
        } catch (error) {
            return false;
        }
    }

    cancelConfirmation = () => {
        const { history, dispatch } = this.props;
        dispatch({ type: actions.SET_CAPTURE_MATRIX, captureMatrix: {} });
        dispatch({ type: actions.SET_SCENE_PICTURES, scenePicturesTaken: {} });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS, captureSceneQuestionIds: [] });
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS, captureSceneSerialNumbers: {} });
        dispatch({ type: actions.SET_ANSWER_DATA, answerData: [] });
        dispatch({ type: actions.SET_DEPENDDENCY_QUESTION, dependencyQuestions: [] });
        dispatch({ type: actions.SET_IMAGE_TO_STITCH, imageToStitch: {} });
        dispatch({ type: actions.SET_POSQUESTION, imgToStitchQuestionIds: [] });
        dispatch({ type: actions.SET_POS_SERIAL_NUMBERS, imageSerialNumbers: {} });
        dispatch({ type: actions.SET_CAPTURE_MATRIX_CLONE, captureMatrixClone: {} });
        dispatch({ type: actions.SET_SCENE_PICTURES_CLONE, scenePicturesTakenClone: {} });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE, captureSceneQuestionIdsClone: [] })
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE, captureSceneSerialNumbersClone: {} });
        dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: {} });
        if (this.state.readOnlyView) {
            if (this.state.locationData.Id) {
                history.push(`/Surveys/${this.state.locationData.Id}`);
            } else {
                history.push(`/Surveys`);
            }

        } else {
            history.push('/Location');
        }
    }
    randerImage = (cvs) => {
        let ctx = cvs.getContext('2d');
        let img1 = new Image();
        img1.onload = function (img) {
            let canvas = ctx.canvas;
            let imgWidth = img1.width, imgHeight = img1.height;
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img1, 0, 0, imgWidth, imgHeight);
        };
        return img1;
    }



    showPopupImageClick = (imageId, questId, scene = false) => {
        const isDoorImage = questId.split("|").length > 1; //attachmentByLabel
        let fullId = "";
        if (isDoorImage) {
            fullId = questId;
            questId = questId.split("|")[0];
        }
        let answerArray = this.props.answerData;
        let answerData = answerArray.filter(e => e.name === questId) || [];
        let imgUrl = '', attachementItem = [], type = '';

        if (answerData.length) {
            let imageItem;
            if (isDoorImage) {
                imageItem = answerData[0]?.attachmentByLabel[fullId];
            } else {
                imageItem = [...answerData[0].attachment];
            }

            imgUrl = imageItem[imageId];
            // imgUrl = filterImage;
            attachementItem = isDoorImage ? imageItem : answerData[0].attachment;
        }
        this.setState({ openImagePop: { isOpen: true, imageId: imageId, url: imgUrl, isFromScene: scene, type: type, attachementItem: attachementItem, questId: isDoorImage ? fullId : questId }, activeIndex: 0 })
    }

    toggle = () => {
        const { showPlanogramPopup } = this.state;
        this.setState({ showPlanogramPopup: !showPlanogramPopup });
    }
    selectedImageToggle = () => {

        this.setState({ openImagePop: { isOpen: false, imageId: '', url: '', activeIndex: 0 } });
    }

    onRemoveImageClick = (e) => {
        const { dispatch, captureMatrix, scenePicturesTaken, answerData, imageSequence, fileToUpload } = this.props;
        const { questid, imageid } = e?.target?.dataset;
        const answerArray = answerData;
        const isDoorImage = questid.split("|").length > 1;
        let fullId = "", questId, sceneCaptureIndex;
        if (isDoorImage) {
            fullId = questid;
            questId = questid.split("|")[0];
            sceneCaptureIndex = Number(questid.split("|")[1]);
        } else {
            questId = questid;
        }
        const fileIndex = answerArray.findIndex(e => e.name === questId);
        let newFileList = [], newScenePictures, newMatrix, newImageSequence;
        if (answerArray[fileIndex]) {
            switch (answerArray[fileIndex].type) {
                case AssessmentType.Scene:
                    newScenePictures = Object.assign({}, scenePicturesTaken);
                    newImageSequence = Object.assign({}, imageSequence);
                    if (newScenePictures[questId].length === 1) {
                        delete newScenePictures[questId]
                        if (isDoorImage) {
                            delete newImageSequence[questId];
                        }
                    } else {
                        newScenePictures[questId].splice(imageid, 1);
                        if (isDoorImage) {
                            newImageSequence[questId].splice(sceneCaptureIndex, 1);
                            newScenePictures[questId].splice(sceneCaptureIndex, 1);
                        } else {
                            newMatrix = captureMatrix;
                            const { row: rowOfImageToRemove, col: colOfImageToRemove } = newScenePictures[questId][imageid];
                            newMatrix[questId][rowOfImageToRemove][colOfImageToRemove] = null;
                            dispatch({ type: actions.SET_CAPTURE_MATRIX, captureMatrix: newMatrix });
                        }
                    }

                    dispatch({ type: actions.SET_SCENE_PICTURES, scenePicturesTaken: newScenePictures });
                    break;

                case AssessmentType.File:
                    const filesTargeted = fileToUpload[questId]
                    filesTargeted.splice(imageid, 1);
                    fileToUpload[questId] = [...filesTargeted];
                    dispatch({ type: 'SET_FILE_TO_UPLOAD', fileToUpload: { ...fileToUpload } });
                    break;
                default:
                    break;
            }
            if (isDoorImage) {
                if (newScenePictures[questId]) {
                    delete answerArray[fileIndex]?.attachmentByLabel[fullId];
                    newFileList = Object.assign({}, answerArray[fileIndex].attachmentByLabel);
                    answerArray[fileIndex].attachmentByLabel = newFileList;
                } else {
                    answerArray.splice(fileIndex, 1);
                }
                dispatch({ type: actions.SET_IMAGE_SEQUENCE, imageSequence: newImageSequence });
            } else {
                answerArray[fileIndex].attachment.splice(imageid, 1);
                newFileList = [...answerArray[fileIndex].attachment];
                answerArray[fileIndex].attachment = newFileList;
            }

            this.setState({ activeIndex: 0, openImagePop: { isOpen: false, imageId: '', url: '' } });
            dispatch({ type: actions.SET_ANSWER_DATA, answerData: answerArray });
        }
    }

    onMoreInfoClick = (moreInfo) => {
        this.setState({ openMoreInfo: { isOpen: true, text: moreInfo } });
    }
    onMoreInfoToggle = () => {
        this.setState({ openMoreInfo: { isOpen: false, text: '' } });
    }

    next = () => {
        const { activeIndex, animating, openImagePop } = this.state;
        const { attachementItem } = openImagePop;
        if (animating) return;
        const nextIndex = activeIndex === attachementItem.length - 1 ? 0 : activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        const { activeIndex, animating, openImagePop } = this.state;
        const { attachementItem } = openImagePop;
        if (animating) return;
        const nextIndex = activeIndex === 0 ? attachementItem.length - 1 : activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }


    goToIndex = (newIndex) => {
        const { animating } = this.state;
        if (animating) return;
        this.setState({ activeIndex: newIndex });
    }

    onBarcodeClose = () => {
        const { appInfo } = this.props;
        this.setState({ openScanner: false, closebarCodeBtn: false });
        if (appInfo.version) {
            let container = document.getElementsByClassName("assessment-wrapper")[0];
            container.style.opacity = "1";
            container.style.background = "#f0ebf8";
            BarcodeScanner.showBackground();
            BarcodeScanner.stopScan();
        }
    }

    handleAcordionChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : false })
    }

    checkTabStatus = (reduxAssetsQuestions, currentAssetId, answers) => {
        const currentAsset = reduxAssetsQuestions[currentAssetId];
        let imcomplete = true, started = false, expectedAnswers = currentAsset.length, expectedRequiredCount = 0, requiredCount = 0, numberOfQuestionAnswered = 0;
        const { match } = this.props;
        const { params } = match;

        for (const quest of currentAsset) {
            const dependencyStatus = this.getDependency(quest);
            let hideElement = dependencyStatus === "hide" ? "hide-question" : (dependencyStatus === "disable" ? "disable-question" : (dependencyStatus === "show" ? "show-question" : ""));
            if (!dependencyStatus) {
                hideElement = quest.defaultDisplay === "hidden" ? "hide-question" : "";
            }
            if (!hideElement && hideElement !== "hide-question" && quest && quest.dependentQuestion && quest.dependentQuestion[0] && quest.dependentQuestion[0].behaviour === 'show' && params.SurveyId > 0) {
                hideElement = "hide-question";
            }
            if (hideElement === "hide-question") {
                expectedAnswers -= 1;
                continue;
            }

            const answerIndex = answers.findIndex(answer => answer?.name === quest?.id);
            const questAnswer = answerIndex !== -1 ? answers[answerIndex] : {};
            if (quest?.required) {
                expectedRequiredCount++;
            }

            if (Object.keys(questAnswer).length) {
                numberOfQuestionAnswered = (questAnswer?.attachment?.length || (questAnswer?.value && questAnswer?.value !== "null")) ? numberOfQuestionAnswered + 1 : numberOfQuestionAnswered;

                requiredCount = quest?.required ? requiredCount + 1 : requiredCount;
            }
        }

        if ((expectedRequiredCount !== 0 && expectedRequiredCount === requiredCount) || (expectedRequiredCount === 0 && expectedAnswers === numberOfQuestionAnswered)) {
            imcomplete = false;
        }

        if (numberOfQuestionAnswered > 0) {
            started = true;
        }

        return { imcomplete, started };
    }

    onBackClick = () => {
        const { history } = this.props;
        const fromSurveyType = history?.location?.state?.fromSurveyType;
        this.cancelConfirmation();
        if (fromSurveyType) {
            history.push('/Location/SurveyType');
        } else {
            history.push('/Location');
        }

    }
    handleClose = () => {
        this.setState({ openSurveyDialog: false, openAlert: false });
    };

    render() {

        const { t, answerDataDictionary, answerData } = this.props;
        const { onSave, state, cancelConfirmation } = this;
        const { typeQuestion, expanded, data, locationData, showPlanogramPopup, planogramImageUrl, openImagePop, openMoreInfo,
            readOnlyView, planogramName, closebarCodeBtn, showPictureCapture, captureQuestName, captureQuestType,
            captureActQuest, captureQuestNum, captureSceneIndex, currentCaptureSerialNumber, questionByAsset, openSurveyDialog, openAlert } = state;

        const { Name: locationName, Code: locationCode } = locationData
        const { isOpen, questId, attachementItem } = openImagePop;
        const { isOpen: moreInfoIsOpen, text: moreInfoText } = openMoreInfo;
        let count = 0;
        let imageSources = [];
        attachementItem?.length > 0 && attachementItem.map((item) => {
            imageSources.push({ imgPath: item, label: item?.caption });

        })
        return (
            <>
                <Header title={t("Survey")} onBackClick={this.onBackClick} showDotOnly={true} />
                {
                    data?.length > 0 ?
                        <>
                            <SecondaryHeader message={{ primaryMessage: locationName + " - " + locationCode, secondaryMessage: t(" Please select from the cooler(s) below:"), icon: <StoreMallDirectoryIcon sx={{ color: "blair.main" }} /> }} showMessage={true} />
                            <Container maxWidth={false} disableGutters>

                                <Box className="survey-box" component="form" onSubmit={onSave}>
                                    <Grid container >
                                        <Grid item sx={{ width: "100%" }} sm={12} md={12} lg={9} xl={9}>
                                            <div>
                                                {
                                                    data.map((v, i) => {
                                                        const panelName = typeQuestion[i].title;
                                                        const { imcomplete, started } = this.checkTabStatus(questionByAsset, typeQuestion[i].id, answerData);

                                                        return (
                                                            <>
                                                                <Accordion
                                                                    expanded={expanded === i}
                                                                    key={i}
                                                                    onChange={this.handleAcordionChange(i)}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1bh-content"
                                                                        id={`${i}${Math.random()}`}
                                                                    >

                                                                        <ListItem sx={{ paddingLeft: 0 }}>
                                                                            <ListItemAvatar sx={{ position: "relative", left: "-5px" }} >
                                                                                {imcomplete && started ? <Avatar className="tab-status started" ><HourglassTopIcon /></Avatar> : (!imcomplete ? <Avatar className="tab-status completed"><CheckCircleIcon /> </Avatar> : <Avatar className="tab-status untouched " ><CheckCircleIcon /> </Avatar>)}
                                                                            </ListItemAvatar>
                                                                            <ListItemText sx={{ position: "relative", left: "-5px" }} primary={panelName} />
                                                                        </ListItem>
                                                                    </AccordionSummary>

                                                                    <div key={i} className="assementAnswer" id={panelName}>
                                                                        <AccordionDetails>
                                                                            {

                                                                                v.questions.map((question, j) => {
                                                                                    count++;
                                                                                    let { name, text, required, hintImage, info, defaultDisplay } = question;
                                                                                    var typeId = parseInt(v.id) - 1;

                                                                                    let dependencyStatus = this.getDependency(question);

                                                                                    let hideElement = dependencyStatus === "hide" ? "hide-question" : (dependencyStatus === "disable" ? "disable-question" : (dependencyStatus === "show" ? "show-question" : ""));

                                                                                    if (!dependencyStatus) {
                                                                                        hideElement = defaultDisplay === "hidden" ? "hide-question" : "";
                                                                                    }
                                                                                    dependencyStatus = (dependencyStatus === "disable" || dependencyStatus === "hide") ? true : false;

                                                                                    if (readOnlyView && !answerDataDictionary[question.id]) {
                                                                                        return null;
                                                                                    }


                                                                                    return (
                                                                                        <VisibilitySensor key={count} onChange={(isVisible) => this.onchanges(isVisible, text, typeId, question.text)}>
                                                                                            <Box id={name} className={`seperator ${hideElement}`}>
                                                                                                <Typography className={dependencyStatus && "label-disable-color"} ><strong>{count}. </strong> {text}{required ? <span className="required-color-red">*</span> : null}
                                                                                                    {hintImage && <span className="image-planogram"><FaImage onClick={() => this.onImageClick(question)} /></span>}
                                                                                                    {info && <FcInfo size="19" title={t('More Info')} className="ml-2" onClick={() => this.onMoreInfoClick(info)} />}
                                                                                                </Typography >
                                                                                                {this.renderAnswer(question, j, false, question.text, dependencyStatus, count)}
                                                                                            </Box>
                                                                                        </VisibilitySensor>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </AccordionDetails>
                                                                    </div>
                                                                </Accordion>


                                                            </>)
                                                    })}
                                            </div>

                                            <div className="scroller-group">
                                                <img alt="top arrow" src={topArrow} hidden={true} className="top-scroller" id="top-scroller-icon" />
                                                <img alt="bottom arrow" src={bottomArrow} hidden={true} className="bottom-scroller" id="bottom-scroller-icon" />
                                            </div>
                                        </Grid>

                                    </Grid>
                                    <Grid container justifyContent="flex-end" alignItems="flex-end" direction="row" sx={{ padding: "20px 18px 20px 0px" }}>
                                        <Button sx={{ mr: 2, }} type="button" onClick={cancelConfirmation} variant="outlined" className="cancel">{readOnlyView ? t('Close') : t('Cancel')}</Button>
                                        {!readOnlyView && <Button type="submit" className="save" variant="contained" >{t("Submit")}</Button>}
                                    </Grid>
                                </Box>
                            </Container>
                        </>
                        : <></>}
                {this.state.scanning && <Dialog
                    fullScreen
                    open={this.state.scanning}
                    sx={{ background: "black" }}
                    className="scannerDialog"
                >
                    <BarcodeComponent scannerResult={this.getScannerResult} setbarcodeimg={this.setbarcodeimg} toggleScanning={this.toggleScanning} getImageofFailedScan={this.getImageofFailedScan} />
                </Dialog>}

                {closebarCodeBtn && <div className='floating-menu' onClick={this.onBarcodeClose}><div className='floating-menu-icon'><BiX size="40" color='#fff' style={{ marginTop: "-4px" }} /></div></div>}
                {showPlanogramPopup &&
                    <Dialog
                        open={showPlanogramPopup}
                        onClose={this.toggle}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("Planogram")}: {planogramName}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <img alt="planogram preview" src={planogramImageUrl} className="top-scroller img-fluid" id="top-scroller-icon" />
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                }
                {isOpen &&
                    <Dialog
                        open={isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle >{t("Images")}</DialogTitle>
                        <DialogContent className="multi-img-popup">
                            {imageSources.length > 0 && <MuiCarousel activeStep={this.state.activeIndex} images={imageSources} />}
                        </DialogContent>
                        <DialogActions>
                            {imageSources.length > 1 && <>
                                <Button color="success" variant="contained" onClick={this.previous}>{t("Previous")}</Button>{' '}
                                <Button color="success" variant="contained" onClick={this.next}>{t("Next")}</Button>
                            </>}
                            <Button color="primary" data-questid={questId} data-imageid={this.state.activeIndex} variant="contained" onClick={this.onRemoveImageClick}>Remove</Button>{' '}
                            <Button color="secondary" variant="contained" onClick={this.selectedImageToggle}>{t("Close")}</Button>
                        </DialogActions>
                    </Dialog>
                }

                {
                    moreInfoIsOpen &&
                    <Dialog open={moreInfoIsOpen} onClose={this.onMoreInfoToggle} centered={true} >
                        <DialogTitle toggle={this.onMoreInfoToggle}> {t("More Info")}</DialogTitle>
                        <DialogContent>
                            {moreInfoText}
                        </DialogContent>
                    </Dialog>
                }

                {showPictureCapture &&
                    <Dialog
                        fullScreen
                        open={showPictureCapture}
                    >
                        <Capture onChange={this.onChange} locationName={locationName} currentCaptureSerialNumber={currentCaptureSerialNumber} captureActQuest={captureActQuest} captureSceneIndex={captureSceneIndex} captureQuestNum={captureQuestNum} captureQuestType={captureQuestType} captureQuestName={captureQuestName} cancelCapture={this.cancelCapture} />
                    </Dialog>
                }
                <CommonDialog
                    open={openSurveyDialog}
                    handleClose={this.handleClose}
                    title={t("Are you sure to submit the Survey?")}
                    contentText={t("By submitting the survey, you agree that your responses will be stored.")}
                    handleConfirm={this.onSaveSurveyData}
                />
                <CommonAlert
                    open={openAlert}
                    handleClose={this.handleClose}
                    severity="error"
                    message={this.state.alertMessage}
                />
            </>
        );
    }
}

function createDictionary(answerData = []) {
    const data = {};
    answerData.forEach(item => {
        data[item.questionNumber] = item.value !== "" || item.attachment;
    });
    return data;
}

function mapStateToProps(state, ownProps) {
    const { appInfo, captureMatrix, dependencyQuestions, answerData, scenePicturesTaken, captureSceneQuestionIds, captureSceneSerialNumbers, fileToUpload, captureMatrixClone, scenePicturesTakenClone, captureSceneQuestionIdsClone, captureSceneSerialNumbersClone, imgToStitchQuestionIds, imageToStitch, imageSerialNumbers, geoLocationData, imageSequence } = state.appReducer;




    return {
        appInfo,
        scenePicturesTaken,
        captureMatrix,
        captureSceneQuestionIds,
        captureSceneSerialNumbers,
        captureMatrixClone,
        scenePicturesTakenClone,
        captureSceneQuestionIdsClone,
        captureSceneSerialNumbersClone,
        answerData,
        answerDataDictionary: createDictionary(answerData),
        dependencyQuestions,
        imgToStitchQuestionIds,
        imageToStitch,
        imageSerialNumbers,
        fileToUpload,
        geoLocationData,
        imageSequence
    };
}
const localizedSurvey = withTranslation()(Survey)
export default connect(mapStateToProps)(localizedSurvey);

