import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';


export default function ActionAlerts() {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Snackbar id="info-box" className="hide" anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}>
            <Alert
                severity="info"
                action={
                    <Button color="inherit" size="small" id="update-installer">
                        Install
                    </Button>
                }
            >
                New Version Available
            </Alert>
        </Snackbar>
    );
}