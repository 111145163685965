import React, { useEffect } from 'react';
import { HashRouter, useHistory } from 'react-router-dom';
import './App.scss';
import Routes from './Routes';
import "./Utils/i18n";
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Utils from './Utils/index';

import actions from './redux/actions';

const AppRoutes = () => {
  const history = useHistory();
  const dispatch = store.dispatch;

  async function handleConnection() {
    let connectionStatus = navigator.onLine;
    store.dispatch({ type: actions.SET_IS_ONLINE, isOnline: connectionStatus });
  }

  Utils.dequeue(history, dispatch);


  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', handleConnection.bind({ history }));
    window.addEventListener('online', handleConnection.bind({ history }));

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', handleConnection.bind({ history }));
      window.removeEventListener('online', handleConnection.bind({ history }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Routes />
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#1565c0",
    },
    success: {
      main: "#00c853",
      variantDeep: "#2e7d32"
    },
    blair: {
      main: "#999696"
    },
    highlight: {
      main: "#dcf6fe"
    }

  },
  components: {
    MuiList: {
      variants: [
        {
          props: { variant: 'duckedTop' },
          style: {
            position: "fixed",
            zIndex: 1,
            width: "100%",
            top: "2.5rem",
            paddingBottom: "0px",

          },
        },
        {
          props: { variant: 'fullHeight' },
          style: {
            minHeight: "80vh",
          },
        },
      ],
    },
    MuiTab: {
      variants: [
        {
          props: { variant: 'eco' },
          style: {
            minWidth: "0px",
            padding: "1px 0px 0px 3px",
            margin: "0px",
            alignItems: "flex-start",
            height: "1.43rem",
            justifyContent: "flex-end!important",

          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'custom1' },
          style: {
            padding: "0rem 0.6rem 0rem 0.7rem",
          },
        },
      ],
    }
  }
});
function App() {
  return (<>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div className="main">
          <HashRouter basename="/">
            <AppRoutes />
          </HashRouter>
        </div>
      </Provider>
    </ThemeProvider>
  </>);
}

export default App;
