import React, { useState, useRef, useEffect } from 'react';
import { BarcodeFormat, BrowserMultiFormatReader, ChecksumException, FormatException, NotFoundException } from '@zxing/library';

const BarCodeScanner = ({ onCodeDecoded }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [code, setCode] = useState("");
    const [videoInputDevices, setVideoInputDevices] = useState([]);
    const [hideSwipper, setHideSwipper] = useState("hide-swipper");
    const codeReader = new BrowserMultiFormatReader();

    useEffect(() => {
        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                setupDevices(videoInputDevices);
            })
            .catch(err => {
                console.error(err);
            });
    }, [codeReader]);
    function setupDevices(videoInputDevices) {
        // get the list of available media devices
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                // find the environment-facing camera
                const environmentCamera = devices.find(device => device.kind === 'videoinput' && device.label.toLowerCase().includes('environment'));

                // use the environment-facing camera as the default camera if available
                let defaultCamera = environmentCamera || null;

                // if no environment-facing camera is found, use the back-facing camera as the default
                if (!defaultCamera) {
                    defaultCamera = devices.find(device => device.kind === 'videoinput' && device.label.toLowerCase().includes('back'));
                }

                // if no camera is found, return an error
                if (!defaultCamera) {
                    throw new Error('No camera found');
                }

                // get the capabilities of the camera
                navigator.mediaDevices.getUserMedia({ video: { deviceId: defaultCamera.deviceId } })
                    .then(stream => {
                        const track = stream.getVideoTracks()[0];
                        const capabilities = track.getCapabilities();
                        let facingMode = capabilities.facingMode[0];

                        // if facing mode is not environment, show an error message
                        if (facingMode !== 'environment') {
                            // set to environment
                            facingMode = 'environment'
                        }

                        setSelectedDeviceId(defaultCamera.deviceId);
                        setVideoInputDevices(videoInputDevices);
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }


    function decodeContinuously(selectedDeviceId) {
        let decoding = false;
        codeReader.decodeFromInputVideoDeviceContinuously(
            selectedDeviceId,
            "video",
            (result, err) => {
                if (result && !decoding) {
                    // properly decoded qr code or barcode
                    setCode(result.getText());
                    // call the onCodeDecoded function to pass the decoded code to the parent component
                    onCodeDecoded(result.getText());
                    // stop the video after decoding the barcode
                    codeReader.stop();
                    decoding = true;
                }

            },
            [
                BarcodeFormat.CODE_128,
                BarcodeFormat.CODE_39,
                BarcodeFormat.CODE_93,
                BarcodeFormat.CODABAR,
                BarcodeFormat.DATA_MATRIX,
                BarcodeFormat.EAN_13,
                BarcodeFormat.EAN_8,
                BarcodeFormat.ITF,
                BarcodeFormat.QR_CODE,
                BarcodeFormat.UPC_A,
                BarcodeFormat.UPC_E,
                BarcodeFormat.UPC_EAN_EXTENSION
            ],
        );
    }

    useEffect(() => {
        decodeContinuously(selectedDeviceId);
    }, [selectedDeviceId]);

    useEffect(() => {
        setTimeout(() => setHideSwipper(""), 2000)
        return () => {
            setHideSwipper("hide-swipper")
        }
    }, [])

    return (
        <div style={{ position: "absolute", height: "200px", top: "31%", left: "30%", maxWidth: "64%" }} >
            { }
            <div className={`swipper ${hideSwipper}`}></div>
            <video id="video" height="200" />
        </div>
    );
}

const BarcodeComponent = ({ toggleScanning, scannerResult }) => {
    const [scanning, setScanning] = useState(false);
    const scannerRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        setTimeout(() => setScanning(true), 1500);
        return () => {
            setScanning(false);
        }
    }, []);

    return (<>
        <div className='barcode-scanner' ref={scannerRef} >
            <button className='barcode-closebtn'
                onClick={(e) => {
                    e.preventDefault();
                    toggleScanning();
                }}>X</button>
            <canvas className="drawingBuffer" />
            {scanning ?
                <BarCodeScanner onCodeDecoded={scannerResult} />
                : null}
        </div>
        <canvas className='img-canvas' ref={canvasRef} />
    </>
    );
};


export default BarcodeComponent;
