import { Box, LinearProgress, Typography } from '@mui/material';


export default function ShowProgress(props) {
  return (
    <Box className="progress-bar">
      <Box>
        <Box>
          <LinearProgress height={18} variant="determinate" {...props} />
        </Box>
        <Box>
          <Typography variant="body2" color="#fff">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography > Uploading image for  Q{props.step}, Total number of image {props.totalSteps}</Typography>
      </Box>
    </Box>
  );
}
