/* eslint-disable array-callback-return */
/*
  @questionJson : param of type object. Contains the jsonobject selected for the survey
  @repeatCount: param of type integer. Contains the number of time the recursive function is executed
  deeClone(): function used to copy an object erasing all reference
  getrepeatedQuestions(): function used to extract the information about the questions (s) that need to be repeated
*/

const deepClone = (objectpassed) => {
    if (objectpassed === null || typeof objectpassed !== 'object') {
        return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    const temporaryStorage = objectpassed.constructor();
    for (const key in objectpassed) {
        temporaryStorage[key] = deepClone(objectpassed[key]);
    }
    return temporaryStorage;
}

const getRepeatedQuestions = (questionJson) => {
    let repeated = {};
    questionJson["sections"].forEach((section) => {

        section["questions"].forEach((question) => {
            if (question.repeat) {
                repeated[section.id] = {};
                repeated[section.id]["questions"] = question.repeat;
                repeated[section.id]["position"] = question.id;
                repeated[section.id]["answerkey"] = question.repeatkey;

            }
        })
    })
    return repeated;
}

export function addRepeatedQuestions(questionJson, repeatCount = 1, showHiddenQuestion = false) {


    if (!Object.keys(questionJson).length) {
        return;
    }
    const paramToInject = getRepeatedQuestions(questionJson);
    if (Object.keys(paramToInject) && !Object.keys(paramToInject).length) {
        return questionJson;
    }
    const questionJsonCopy = deepClone(questionJson);
    const sectionsOfQuestionJson = questionJson["sections"];
    let newQuestions = {}, secPos = {}, questPos = {};

    for (const [index, section] of sectionsOfQuestionJson.entries()) {
        const sectionId = section["id"]
        const idQuestAfterWhichToInject = paramToInject[sectionId]["position"]; //question after which repeated questions will be added
        const answerkey = paramToInject[sectionId]["answerkey"];
        if (paramToInject[sectionId]) {
            secPos[sectionId] = index;
            const getQuestionToinject = paramToInject[sectionId]["questions"];  // will get questions of a given section

            for (const [qIndex, question] of section["questions"].entries()) {
                const questionCopy = deepClone(question);
                let idRef = Number(idQuestAfterWhichToInject.split('~')[1]);
                let currId = questionCopy.id.split('~');
                let newIndex = `${currId[0]}~${idRef + qIndex + 1}~${currId[2]}`;
                if (questionCopy["id"] === idQuestAfterWhichToInject) {
                    questPos[sectionId] = qIndex;

                }

                if (getQuestionToinject.includes(questionCopy["id"])) {
                    questionCopy.id = newIndex;
                    questionCopy.name = newIndex;
                    questionCopy["dependentQuestion"] = [
                        {
                            "question": idQuestAfterWhichToInject,
                            "behaviour": "show",
                            "answerkey": answerkey
                        }
                    ];
                    if (!showHiddenQuestion) {
                        questionCopy.defaultDisplay = "hidden";
                    }

                    questionCopy.repeated = true;

                    if (newQuestions[sectionId]) {
                        newQuestions[sectionId].push(questionCopy);
                    } else {
                        newQuestions[sectionId] = [];
                        newQuestions[sectionId].push(questionCopy);
                    }
                    continue;
                }

            }
        }

    }


    Object.keys(paramToInject) && Object.keys(paramToInject).length && Object.keys(paramToInject).map((sectionId, key) => {

        const scPos = secPos[sectionId]; // sectionPosition, the index at which it can be found in the array of sections
        const qtPos = questPos[sectionId]; // questionPosition, the index at which it can be found in the array of questions

        if (questionJsonCopy["sections"][scPos]) {
            const oldQuestions = questionJsonCopy["sections"][scPos]["questions"];
            questionJsonCopy["sections"][scPos]["questions"].splice(qtPos + 1, oldQuestions.length);
            questionJsonCopy["sections"][scPos]["questions"] = [...questionJsonCopy["sections"][scPos]["questions"], ...newQuestions[sectionId]]
        }
    })

    if (repeatCount === 1) {
        return questionJsonCopy;
    } else {
        return addRepeatedQuestions(questionJsonCopy, repeatCount - 1, showHiddenQuestion)
    }


}