import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IconButton, List, ListItem, Divider, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import dbUtil from '../../Utils/dbUtil';
import Header from '../../Component/Header';
import SecondaryHeader from '../../Component/SecondaryHeader';

const SurveyType = () => {
    const locationParams = useLocation();
    const history = useHistory();
    const [locationData, setLocationData] = useState(locationParams.state);

    const onSurveyTypeClick = (item) => {
        history.push({
            pathname: `/Survey/${locationData.locationId}/${item.SurveyTypeId}`,
            state: { fromSurveyType: true }
        });
    }

    const loadMultipleList = async () => {
        if (!locationData) {
            const newLocationData = await dbUtil.get('multipleSurveyType') || {};
            setLocationData(newLocationData);
        }
    }

    useEffect(() => {
        loadMultipleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBackClick = () => {
        history.push('/Location');
    }

    return <>
        <Header onBackClick={onBackClick} clientIcon={true} title="Survey Type" showDotOnly={true} isShowBackButton={true} />
        <SecondaryHeader message={{ primaryMessage: locationData?.locationName, secondaryMessage: locationData?.code, icon: <StoreMallDirectoryIcon sx={{ color: "blair.main" }} /> }} showMessage={true} />
        <div style={{ padding: "0rem 0.7rem", position: "relative", top: "1.5rem" }}>
            <List>
                {locationData?.surveyTypes?.map((surveyType, index) => {
                    let isShowRightMark = false;
                    if (locationData?.surveyLocationInfo?.length) {
                        isShowRightMark = locationData?.surveyLocationInfo.some(e1 => e1.SurveyTypeId === surveyType.SurveyTypeId);
                    }
                    return (
                        <>
                            {index > 0 && <Divider variant="inset" component="li" />}
                            <ListItem key={index} alignItems="flex-start" sx={{ paddingLeft: "0px" }}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="comments" >
                                        {isShowRightMark ? <CheckCircleIcon /> : <></>}
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "primary.main" }} onClick={() => onSurveyTypeClick(surveyType)}><ContentPasteSearchIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ mt: 1 }}
                                    primary={surveyType.SurveyTypeName}
                                    secondary={surveyType.Category + " " + surveyType.SurveyCategory}
                                ></ListItemText>
                            </ListItem >
                        </>
                    );
                })}
            </List>
        </div>
    </>
}

export default SurveyType;