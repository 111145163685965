import React from 'react';
import { useTranslation } from "react-i18next";
import { FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel, List, ListItem, Avatar, Typography, ListItemAvatar, ListItemText } from '@mui/material';
import { AiOutlineCloseCircle } from "react-icons/ai";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';;

const SecondaryHeader = ({ search, tab, message, showSearch = false, showTab = false, showMessage = false }) => {

    const { t } = useTranslation();

    return (

        <List variant="duckedTop">

            {showMessage ? <ListItem sx={{ bgcolor: "highlight.main" }} key={0} alignItems="flex-start">
                <ListItemAvatar sx={{ position: "relative", left: "0rem", top: "-0.1rem" }}>
                    {message?.icon}
                </ListItemAvatar>
                <ListItemText sx={{ position: "relative", top: "7px", left: "-28px", margin: "0px" }}
                    primary={<Typography sx={{ fontSize: "12px", color: "blair.main" }}>{message?.primaryMessage}</Typography>}
                    secondary={<Typography sx={{ fontSize: "12px", color: "blair.main", marginLeft: "1px" }}> {message?.secondaryMessage} </Typography>}
                ></ListItemText>
            </ListItem> : <></>}

            {!showSearch && !showTab ? <></> :
                <ListItem sx={{ background: "#fff", paddingLeft: "0px", paddingRight: "0px" }} key={1}>

                    <Grid container variant="custom1" >
                        <Grid item xs={9} md={10} lg={11} >
                            {showSearch ? <FormControl sx={{ width: '100%' }} variant="outlined" size="small">
                                <InputLabel >{t("Search")}</InputLabel>
                                <OutlinedInput
                                    type='text'
                                    ref={search?.searchRef}
                                    onKeyUp={search?.handleSearch}
                                    onPaste={search?.handlePaste}
                                    placeholder={t("Start typing...")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={search?.handleCleanTextField}
                                                edge="end"
                                            >
                                                <AiOutlineCloseCircle style={{ color: search?.enableClean }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t("Search")}
                                />
                            </FormControl> : <></>}
                        </Grid>
                        <Grid item xs={3} md={2} lg={1}>
                            {showTab ?
                                <Tabs
                                    value={tab?.currentTabValue || 0}
                                    onChange={tab?.handleChangeTab}
                                    textColor="primary"
                                    indicatorColor="transparent"
                                >
                                    <Tab variant="eco" id={0} icon={<Avatar sx={{ bgcolor: tab?.currentTabValue === 0 || !tab?.currentTabValue ? "primary.main" : "" }}><ViewListIcon fontSize="medium" /></Avatar>} />
                                    <Tab variant="eco" id={1} icon={<Avatar sx={{ bgcolor: tab?.currentTabValue === 1 ? "primary.main" : "" }}><MapIcon fontSize="medium" /></Avatar>} />
                                </Tabs>
                                : <></>}
                        </Grid>
                    </Grid>

                </ListItem>}
        </List>
    )
}

export default SecondaryHeader;