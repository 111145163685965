import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const CommonAlert = (props) => {
  const { open, handleClose, severity, message } = props;
  const vertical = 'bottom'
  const horizontal = 'center'
  return (
    <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CommonAlert;
