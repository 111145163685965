import actions from '../actions';

const initialState = {
    appTitleName: 'CoolR',
    analysisResult: { data: null, loading: false },
    products: { data: null, loading: false },
    selectedProduct: {},
    userEmail: '',
    userPassword: '',
    clientsList: [],
    selectClient: 0,
    comboData: {},
    geoLocationData: { currentAddress: '', mapCoordinates: { lat: 0, lng: 0 } },
    loaderOpen: false,
    isOnline: true,
    appInfo: { version: '', name: '', id: '', build: '' },
    captureMatrix: {},
    captureMatrixClone: {},
    scenePicturesTaken: {},
    scenePicturesTakenClone: {},
    captureSceneSerialNumbers: {},
    captureSceneSerialNumbersClone: {},
    captureSceneQuestionIds: [],
    captureSceneQuestionIdsClone: [],
    localLanguage: "En",
    showProgress: { show: false, value: 0, step: 0, totalSteps: 0 },
    answerData: [],
    dependencyQuestions: [],
    imgToStitchQuestionIds: [],
    imageToStitch: {},
    imageSerialNumbers: {},
    fileToUpload: {},
    imageSequence: []
};

const assignStateValue = (state, action) => {
    return { ...state, [action.type]: action[action.type] };
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_UPLOADED_IMAGE:
        case actions.SET_SELECTED_PRODUCT:
        case actions.SET_MULTI_UPLOADED_IMAGE:
            return assignStateValue(state, action);
        case actions.SET_USER_DATA:
            let userDataObj = { ...action.userData }
            if (action.clientId && userDataObj && userDataObj.tags) {
                userDataObj.tags.ClientId = action.clientId
            }
            return { ...state, ...{ userData: userDataObj } };
        case actions.SET_USER_EMAIL:
            return { ...state, userEmail: action.userEmail, clientsList: action.clientsList };
        case actions.SET_USER_PASSWORD:
            return { ...state, userPassword: action.userPassword };
        case actions.SET_CLIENT_SELECT:
            return { ...state, selectClient: action.selectClient };
        case actions.SET_CLIENT_LIST_DATA:
            return { ...state, clientsList: action.clientsList };
        case actions.SET_COMBO_DATA:
            return { ...state, comboData: action.comboData };
        case actions.RESET_STATE:
            return initialState;
        case actions.SET_GEO_LOCATION_DATA:
            return { ...state, geoLocationData: action.geoLocationData };
        case actions.UPDATE_LOADER_STATE:
            return { ...state, loaderOpen: action.loaderOpen };
        case actions.SET_IS_ONLINE:
            return { ...state, isOnline: action.isOnline };
        case actions.SET_APP_INFO:
            return { ...state, appInfo: action.appInfo };
        case actions.SET_CAPTURE_MATRIX:
            return { ...state, captureMatrix: action.captureMatrix };
        case actions.SET_CAPTURE_MATRIX_CLONE:
            return { ...state, captureMatrixClone: action.captureMatrixClone };
        case actions.SET_SCENE_PICTURES:
            return { ...state, scenePicturesTaken: action.scenePicturesTaken };
        case actions.SET_SCENE_PICTURES_CLONE:
            return { ...state, scenePicturesTakenClone: action.scenePicturesTakenClone };
        case actions.SET_CAPTURE_SCENE_QUESTION_IDS:
            let newQuestionIds;
            if (Array.isArray(action.captureSceneQuestionIds)) {
                newQuestionIds = action.captureSceneQuestionIds
            } else {
                if (state.captureSceneQuestionIds.findIndex(id => id === action.captureSceneQuestionIds) > -1) {
                    return state;
                }
                newQuestionIds = [...state.captureSceneQuestionIds, action.captureSceneQuestionIds]
            }

            return { ...state, captureSceneQuestionIds: newQuestionIds };
        case actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE:
            let newQuestionIdsClone;
            if (Array.isArray(action.captureSceneQuestionIdsClone)) {
                newQuestionIdsClone = action.captureSceneQuestionIdsClone
            } else {
                if (state.captureSceneQuestionIdsClone.findIndex(id => id === action.captureSceneQuestionIdsClone) > -1) {
                    return state;
                }
                newQuestionIdsClone = [...state.captureSceneQuestionIdsClone, action.captureSceneQuestionIdsClone]
            }

            return { ...state, captureSceneQuestionIdsClone: newQuestionIdsClone };
        case actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS:
            return { ...state, captureSceneSerialNumbers: action.captureSceneSerialNumbers };
        case actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE:
            return { ...state, captureSceneSerialNumbersClone: action.captureSceneSerialNumbersClone };
        case actions.SET_CURRENT_LOCAL_LANGUAGE:
            return { ...state, localLanguage: action.localLanguage };
        case actions.SET_UPLOAD_IN_PROGRESS:
            const newState = { ...state.showProgress, ...action.showProgress }
            return { ...state, showProgress: newState }
        case actions.SET_ANSWER_DATA:
            return { ...state, answerData: action.answerData }
        case actions.SET_DEPENDDENCY_QUESTION:
            return { ...state, dependencyQuestions: action.dependencyQuestions }
        case actions.SET_POSQUESTION:
            let newPosQuestion;
            if (Array.isArray(action.imgToStitchQuestionIds)) {
                newPosQuestion = action.imgToStitchQuestionIds
            } else {
                if (state.imgToStitchQuestionIds.findIndex(question => question === action.imgToStitchQuestionIds) > -1) {
                    return state;
                }
                newPosQuestion = [...state.imgToStitchQuestionIds, action.imgToStitchQuestionIds]
            }
            return { ...state, imgToStitchQuestionIds: newPosQuestion }
        case actions.SET_IMAGE_TO_STITCH:
            return { ...state, imageToStitch: action.imageToStitch }
        case actions.SET_POS_SERIAL_NUMBERS:
            return { ...state, imageSerialNumbers: action.imageSerialNumbers }
        case actions.SET_FILE_TO_UPLOAD:
            return { ...state, fileToUpload: action.fileToUpload }
        case actions.SET_IMAGE_SEQUENCE:
            return { ...state, imageSequence: action.imageSequence }
        default:
            return state;
    }
}

export default appReducer;


