import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apis } from '../../httpUtil';
import unileverLogo from '../../Assets/images/login-images/unilever-header.png';

const HeaderLogo = ({ onError }) => {
    const userData = useSelector(state => state.appReducer.userData);
    const [clientLogo, setClientLogo] = useState();
    const { tags = {} } = userData;
    const { ClientName } = tags
    useEffect(() => {
        let clientId = tags && Number(tags.ClientId) ? Number(tags.ClientId) : "";
        let clientLogoURL = clientId && `${apis.ClientLogoBaseUrl}?ImageName=${clientId}.png&ImageType=ClientLogo`;
        if (ClientName.toLowerCase().indexOf('unilever') > -1) {
            setClientLogo(unileverLogo);
        } else {
            setClientLogo(clientLogoURL);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])
    return (
        <div className={'app-header-logo'}>
            {clientLogo && <img src={clientLogo} width="80" alt="" className="header-logo"/>}
        </div>
    );
};

export default HeaderLogo;
