import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, FormLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import actions from '../../redux/actions';

const ClientSelect = memo(function (props) {

    const dispatch = useDispatch();
    const {t} = props;
    //Set user select client in redux
    const memoizedSetClientSelect = useCallback((e) => dispatch({ type: actions.SET_CLIENT_SELECT, selectClient: e.target.value }), [dispatch]);

    const selectClient = useSelector(state => state.appReducer.selectClient);
    const clientsList = useSelector(state => state.appReducer.clientsList) || [];

    return clientsList.length > 0 ? <div className="mb-3">
        <FormControl key="user-client-control" sx={{ minWidth: 120, width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">{t("Client")}</InputLabel>
            <Select
                value={selectClient}
                label="Client"
                onChange={memoizedSetClientSelect}
                name="Client"
            >
                <MenuItem key={0} value={0}>
                    <em>{t("Select Client")}</em>
                </MenuItem>
                {clientsList.map(d => <MenuItem key={d.Key} value={d.Key}> {d.Value}</MenuItem>)}
            </Select>
        </FormControl>

    </div> : <div></div>
})

export default ClientSelect;