import { request, apis } from "../httpUtil";
import actions from '../redux/actions'
import { store } from '../redux/store';
import axios from 'axios';
import Geocode from "react-geocode";
import dbUtil from './dbUtil';
import imageCompression from 'browser-image-compression';
import { App } from '@capacitor/app';
import { Tooltip, Button } from '@mui/material';


const sceneType = {
    PRODUCT_ANALYSIS: "Product Analysis",
    POS: "POS",
};
const utils = {
    dashboardMenuId: 7184,
    Google_ApiKey: 'AIzaSyDsD1XV1hcrT05Rs896wUQ7wC7Dm50Pc3A',
    DATE_TIME_FORMAT: "MM/DD/YYYY hh:mm:ss A",
    DATE_FORMAT: "MM/DD/YYYY",
    routeDirection: ['/Survey', '/Location', '/Surveys', '/Pending-Survey', '/SurveyReport'],
    APP_VERSION: "0.3",
    INITIAL_NUMBER_OF_RECORDS: 300,
    DECIMAL_POSITION: 2,

    getFormData: (props) => {
        let formData = new FormData();
        for (let key in props) {
            formData.append(key, props[key]);
        }
        return formData;
    },
    getSubdomain() {
        let host = window.location.host;
        let subdomain = host.split('.')[0];
        return subdomain
    },
    extractMenu(menuArray, finalMenuArray) {
        for (let i = 0, arrayLength = menuArray && menuArray.length; i < arrayLength; i++) {
            if (menuArray[i].children && menuArray[i].children.length) {
                this.extractMenu(menuArray[i].children, finalMenuArray)
            } else {
                finalMenuArray.push(menuArray[i].url)
            }
        }
    },
    getAssignedRoutes(userData) {
        let tempArray = (userData && userData.menu && userData.menu.children) ? userData.menu.children : [];
        if (tempArray.length) {
            const result = tempArray.filter(obj => obj.id === this.dashboardMenuId);
            let menuArray = result[0] && result[0].children ? result[0].children : [], finalMenuArray = []
            this.extractMenu(menuArray, finalMenuArray)
            return finalMenuArray
        }
    },
    afterLogin(userRoutes, history, dispatch) {
        let searchParams = history.location ? history.location.search : "";
        let pathname = history.location.pathname;
        if (!!pathname && pathname !== '/' && (pathname.indexOf('/Survey') > -1 || userRoutes.includes(pathname))) {
            let queryString = searchParams.replace('?redirectUrl=', "/").split('?');
            history.replace({ pathname: queryString[0], search: queryString.length > 1 ? queryString[1] : "" });
        }
        else {
            window.location.href = window.location.origin + "/#/Location"
        }
    },

    reLogin: function (history, dispatch) {
        let params = { method: 'GET', credentials: 'include', url: apis.login };
        const transport = axios.create({ withCredentials: true });
        if (navigator.onLine) {
            transport(params).then((response) => {
                if (response && response.status === 200) {
                    const userRoutes = this.routeDirection;
                    if (response.data.message === "Logged in" && userRoutes.length) {
                        dispatch({ type: actions.SET_USER_DATA, userData: response.data });
                        this.afterLogin(userRoutes, history, dispatch);
                        this.getGeoLocation(dispatch);
                        this.getSurveyTypeData(history);
                        this.setAppInfo(dispatch);
                        dbUtil.set('userData', response.data);
                    } else {
                        history.replace({ pathname: "/" });
                    }
                }
            });
        } else {
            dbUtil.get('userData').then((userData) => {
                dispatch({ type: actions.SET_USER_DATA, userData: userData });
            }).catch((err) => {
                console.log(err);
            });
        }
    },
    getGeoLocation: function (dispatch) {
        Geocode.setApiKey(this.Google_ApiKey);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((coordinate) => {
                let latitude = coordinate.coords.latitude;
                let longitude = coordinate.coords.longitude;
                Geocode.fromLatLng(latitude, longitude).then(
                    (response) => {
                        const address_component = response.results[0].formatted_address;
                        let currentAddressData = {
                            currentAddress: address_component,
                            mapCoordinates: {
                                lat: latitude,
                                lng: longitude
                            }
                        };
                        dispatch({ type: actions.SET_GEO_LOCATION_DATA, geoLocationData: currentAddressData });
                    },
                    (error) => {
                        console.info(error);
                    }
                );
            });
        } else {
            alert("Geolocation is not supported by this browser.");
            return;
        }
    },
    isMobile() {
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    },
    getFilterData(newData, OldData, id) {
        if (newData && newData.length) {
            for (let d of newData) {
                let findIndexObj = OldData.findIndex(el => el[id] === d[id]);
                if (findIndexObj > -1) {
                    OldData[findIndexObj] = d;
                } else {
                    OldData.push(d)
                }
            }
        }
        if (!newData) {
            OldData.recordCount = newData.recordCount;
            OldData.TotalCount = newData.TotalCount
        }
        return OldData;
    },
    async getSurveyTypeData(history) {
        let { dispatch } = store;
        let params = { action: 'list', asArray: 0, start: 0, limit: 0, storeName: apis.storeName.REQ_SurveyType };
        const response = await request({
            url: apis.SurveyType, params, history, withBar: dispatch, isOfflineModeEnable: true, defaultData: {
                records: []
            }
        });
        if (response && response.records) {
            dbUtil.set('surveyType', response);
        }
    },
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    loadPlanogramImage(AssetId) {
        return `${apis.url}/Controllers/CoolerImagePreview.ashx?AssetId=${AssetId}&ImageType=PlanogramByAsset`
    },

    async setAppInfo(dispatch) {
        try {
            const appInfo = await App.getInfo();
            const { version, name, id, build } = appInfo;
            dispatch({ type: actions.SET_APP_INFO, appInfo: { version: version, name: name, id: id, build: build } });
        } catch (error) {

        }
    },
    getStitchedImageUrl(uniqueGuid) {
        try {
            return `${apis.url}/controllers/CoolerImagePreview.ashx?guid=${uniqueGuid}&ImageType=stitched`
        } catch (e) {
            console.info(e)
        }
    },

    async processImage(imageFile) {
        if (Array.isArray(imageFile)) {
            const allImages = [];
            for (const image of imageFile) {
                const output = await this.compressImage(image);
                allImages.push(output);
            }
            return allImages;
        } else {
            return await this.compressImage(imageFile);
        }

    },

    async compressImage(imageFile) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1000,
            useWebWorker: true
        }

        try {
            const uncompressedImageSize = imageFile?.size / 1024 / 1024;
            if (uncompressedImageSize < 0.8) {
                return imageFile;
            }
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.log(error);
        }
    },
    HHTP_REQUEST_TIMEOUT: 30000,
    UPLOAD_TIMEOUT: 10 * 60 * 1000,
    checkForMissingParameters(SerialNumbers, imgToStitchQuestionIds, dispatch, history) {
        let paramError = [];
        if (!dispatch) {
            paramError.push("dispatch");
        }
        if (!history) {
            paramError.push("history");
        }
        if (!SerialNumbers && Object.keys(SerialNumbers).length < 1) {
            paramError.push("imageSerialNumbers");
        }
        if (!imgToStitchQuestionIds || imgToStitchQuestionIds.length < 1) {
            paramError.push("imgToStitchQuestionIds");
        }
        if (paramError.length > 0) {
            console.info(`Info: Can not run this function; missing Parameter ${paramError.join(",")}`);
            return true;
        }
        return false;
    },
    stitchTypes: ["scene", "multiFileStitch"],
    async uploadToStitch({ scene, uploadType, storeNumber, matrix, dispatch, imageSequence, history, disableLoader = false }) {
        let isParameterMissing, captureSceneQuestionIds, scenePicturesTaken, captureSceneSerialNumbers;
        if (this.stitchTypes.indexOf(uploadType) !== -1) {
            captureSceneQuestionIds = scene.captureSceneQuestionIdsDetail;
            scenePicturesTaken = scene.scenePicturesTakenDetail;
            captureSceneSerialNumbers = scene.captureSceneSerialNumbersDetail;
            isParameterMissing = this.checkForMissingParameters(captureSceneSerialNumbers, captureSceneQuestionIds, dispatch, history);
        } else {
            return false;
        }

        if (isParameterMissing) {
            return false;
        }
        const stitchedUrl = {};

        try {
            let id = "", SerialNumber = "", captureImages, currentMatrix = "", orderIndex = "", params = {};
            if (this.stitchTypes.indexOf(uploadType) !== -1) {
                id = captureSceneQuestionIds[0];
                SerialNumber = captureSceneSerialNumbers && captureSceneSerialNumbers[id];
                captureImages = scenePicturesTaken[id].map(scene => scene.file);
                currentMatrix = matrix[id];
                if(currentMatrix){
                    orderIndex = this.getCaptureOrder(currentMatrix)
                }else if(imageSequence){
                    const sortedIndex = imageSequence[id].sort((a, b) => a - b);
                    orderIndex = sortedIndex.join(",");
                    captureImages = [];
                    sortedIndex.map((orderId) =>{
                        scenePicturesTaken[id].map((scene) => {
                            if(Number(orderId) === Number(scene.gridIndex)){
                                captureImages.push(scene.file);
                            }
                        });
                    })
                }else{
                    orderIndex = null;
                }
               
                params["SceneType"] = uploadType === "scene" ? sceneType.PRODUCT_ANALYSIS : sceneType.POS;
            }

            const compressImages = await this.processImage(captureImages);
            params = { ...params, action: 'uploadImage', StoreNumber: storeNumber, SerialNumber: SerialNumber, GridOrder: orderIndex, files: compressImages, timeout: this.UPLOAD_TIMEOUT };
            const stitchResponse = await request({ url: apis.StitchImages, params, history, withBar: true, disableLoader, silentMode: true });

            if (stitchResponse?.length > 0 && stitchResponse[0].Success) {
                stitchedUrl[id] = this.getStitchedImageUrl(stitchResponse[0].UniqueGuid);
            }
        } catch (err) {
            console.info(err);
        }

        return stitchedUrl;
    },

    incrementRowIndex: (currentRow) => {
        return currentRow + 100;
    },

    incrementColIndex: (currentCol) => {
        return currentCol + 1;
    },

    getCaptureOrder(matrix) {

        //TODO get each row length and verify get the  difference between longest row and any other 
        //If difference is greater than 2 issue a warning to user that captures on grid do not form a square or rectangle
        //If length of any row exeeds 100, issue an error message to the user
        let rowLengths = [], prevRowLength = 0, longestRowValue = 0;

        //get rows length
        for (let i = 0; i < matrix.length; i++) {
            const row = matrix[i];
            if (row.length >= 100) {
                console.warning("Grid overload. Captures on a rows must not exceed 100. Kindly adjust and try again");
                return { success: false, data: "" };
            }
            if (row.length > prevRowLength) {
                longestRowValue = row.length;
            } else {
                rowLengths.push(row.length);
            }
            prevRowLength = row.length;

        }

        //compare longest row with other rows
        for (const len of rowLengths) {
            const diff = longestRowValue - len;
            if (diff > 2) {
                console.warning("Ensure Captures form perfect grid to get better stitching.");

            }
        }

        //get row and column indexes
        let orderIndex = [], colStart = 1001, firstNonNullValue = false;

        for (let i = 0; i < matrix.length; i++) {
            for (let j = 0; j < matrix[i].length; j++) {
                if (matrix[i][j] === null) {
                    continue;
                } else {
                    if (!firstNonNullValue) {
                        if (orderIndex.length - 1 < 0) {
                            colStart = 1001;
                        }
                        orderIndex.push(colStart);
                        firstNonNullValue = true
                        continue;
                    }
                }
                let prevIndex = orderIndex[orderIndex.length - 1]
                orderIndex.push(this.incrementColIndex(prevIndex));
            }
            firstNonNullValue = false;
            colStart = this.incrementRowIndex(colStart);

        }
        return orderIndex.join(",")
    },
    BarcodeReaderTypeList: [
        { label: "Auto Detect", value: "auto-detect" },
        { label: "Code 128", value: "code_128_reader" },
        { label: "Code 39", value: "code_39_reader" },
        { label: "Code 39 vin", value: "code_39_vin_reader" },
        { label: "Code 93", value: "code_93_reader" },
        { label: "Earn", value: "ean_reader" },
        { label: "Earn 8", value: "ean_8_reader" },
        { label: "Codabar", value: "codabar_reader" },
        { label: "UPC", value: "upc_reader" },
        { label: "UPC-E", value: "upc_e_reader" },
        { label: "Interleaved 2 of 5", value: "i2of5_reader" },
        { label: "Standard 2 of 5", value: "2of5_reader" }
    ],
    reformatLongText: (text, maxLen, class_name) => {
        const newText = text?.length > maxLen ? `${text.substring(0, maxLen)}...` : text;
        return <Tooltip title={text?.length > maxLen ? text : ""} placement="top-start">
            <Button className={class_name}>
                {newText}
            </Button>
        </Tooltip>;
    },
    isRunning: false,
    DEQUEUE_FREQUENCY: 10000,
    async dequeue(history, dispatch) {
        let me = this;
        async function startTimer() {
            await new Promise((resolve) =>
                setTimeout(resolve, me.DEQUEUE_FREQUENCY));
        }

        if (this.isRunning) {
            return;
        }
        this.isRunning = true;

        let connectionStatus = navigator.onLine;

        try {
            if (connectionStatus) {
                const surveyParam = await dbUtil.get('Survey') || [];
                const cloneSurveyParam = [...surveyParam]
                if (surveyParam.length) {

                    let stitchedUrl = {}
                    for (const index in surveyParam) {
                        const jsonSurveyData = JSON.parse(surveyParam[index].SurveyData);
                        if (surveyParam[index]["offlineSceneCaptureData"] && surveyParam[index]["offlineSceneCaptureData"]["dataCount"] > 0) {
                            let { captureSceneQuestionIds, scenePicturesTaken, captureSceneSerialNumbers, storeNumber, captureMatrix, imageSequence } = surveyParam[index]["offlineSceneCaptureData"];

                            for (const survey of jsonSurveyData) {
                                if (survey?.type && me.stitchTypes.indexOf(survey?.type) !== -1) {
                                    let captureSceneQuestionIdsDetail = captureSceneQuestionIds.filter(id => id === survey.questionId);
                                    let scenePicturesTakenDetail = { [survey.questionId]: scenePicturesTaken[survey.questionId] };
                                    let captureSceneSerialNumbersDetail = { [survey.questionId]: captureSceneSerialNumbers[survey.questionId] };
                                    let sceneTypeParams = { captureSceneQuestionIdsDetail, scenePicturesTakenDetail, captureSceneSerialNumbersDetail };
                                    const sceneUrl = await this.uploadToStitch({ scene: sceneTypeParams, uploadType: survey.type, matrix: captureMatrix, storeNumber, imageSequence, dispatch, history, disableLoader: true });
                                    stitchedUrl = { ...stitchedUrl, ...sceneUrl };
                                    if (sceneUrl && Object.keys(sceneUrl).length !== captureSceneQuestionIdsDetail.length) {
                                        return;
                                    }
                                    survey.attachment = stitchedUrl[survey.questionId];
                                    delete survey.type;
                                }
                            }
                            surveyParam[index].SurveyData = JSON.stringify(jsonSurveyData);
                        }

                        const response = await request({ url: apis.Survey, params: { ...surveyParam[index], timeout: this.UPLOAD_TIMEOUT }, history, disableLoader: true, silentMode: true });
                        if (response && response.success) {
                            let cloneIndex = cloneSurveyParam.findIndex(e => (e.SurveyData === surveyParam[index].SurveyData && e.LocationId === surveyParam[index].LocationId));
                            cloneSurveyParam.splice(cloneIndex, 1);
                            await dbUtil.set('Survey', cloneSurveyParam);
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.isRunning = false;
            await startTimer();
            me.dequeue(history, dispatch);
        }

    },
}

export default utils;