import React, { memo, useEffect, useState } from 'react';
import Header from '../../Component/Header';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import AssessmentIcon from '@mui/icons-material/Assessment';

dayjs.extend(utc);

const SurveyReport = (props) => {
    const history = useHistory();
    const [userDataList, setUserDataList] = useState({ records: [] });
    const { t } = useTranslation();
    const userData = useSelector(state => state.appReducer.userData);

    useEffect(() => {
        geyUserSurveyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const geyUserSurveyData = async () => {
        const params = {
            storeName: apis.storeName.REQ_UserSurveyCount,
            action: 'GetUserSurveyCount',
            asArray: 0
        };
        const response = await request({ url: apis.Survey, params, history, isOfflineModeEnable: true, defaultData: { records: [] } });
        if (response && response.data.records) {
            setUserDataList({ records: response.data.records });
        }
    }
    const openSurvey = async (record) => {
        history.push({
            pathname: `/Surveys`,
            state: { isShowUserInfo: true }
        });
    }

    return (

        <>
            <Header clientIcon={true} title={t("Survey Report")} isShowMenuOption={true} isComingFromLocation={true} />
            <div style={{ padding: "0rem 0.7rem", position: "relative", top: "-1rem" }}>
                <List>
                    {userDataList.records.length > 0 ?
                        userDataList.records.map((survey, index) => {
                            return (
                                <div key={index}>
                                    {index > 0 && <Divider variant="inset" component="li" />}
                                    <ListItem alignItems="flex-start" sx={{ paddingLeft: "0px" }} >
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: "primary.main" }} onClick={() => openSurvey(survey)}><AssessmentIcon /></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={survey.TotalSurvey}
                                            secondary={
                                                <React.Fragment>
                                                    <div>{survey.Duration + " s"}</div>
                                                    <div> {userData.tags.Username}</div>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </div>
                            )
                        })
                        : <div style={{ textAlign: "center" }}><h3 className="text-center">{t("No Available Survey!")}</h3></div>
                    }
                </List>
            </div>
        </>
    )
}
export default memo(SurveyReport)