/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router-dom';
import { Typography, Box,  IconButton, List, ListItem, Divider, Avatar, ListItemAvatar, ListItemText, ListItemSecondaryAction, Menu, MenuItem, ListItemIcon } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Header from '../../Component/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import Utils from '../../Utils';
import { useTranslation } from "react-i18next";
import dbUtil from '../../Utils/dbUtil';
import MapSearch from "../../Component/MapSearch";
import Swal from 'sweetalert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import { Visibility } from '@mui/icons-material';
import { Directions } from '@mui/icons-material';
import SecondaryHeader from '../../Component/SecondaryHeader';
import CommonAlert from '../../Component/CommonAlert';


const useStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiPaper-root': {
            border: "none", // Remove the border from the menu
            // boxShadow: theme.shadows[1]
        },
    },
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            indicatorColor="transparent"
            className="location-tab"
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Location = () => {
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const searchRef = useRef();
    const theme = useTheme();
    let history = useHistory();
    const [showMap, setShowMap] = useState(false);
    const [locationData, setLocationData] = useState({ records: [], recordCount: 0 });
    const geoLocationData = useSelector(state => state.appReducer.geoLocationData);
    const [startIndex, setStartIndex] = useState(0);
    const [enableClean, setEnableClean] = useState("rgb(205 205 205)");
    const { mapCoordinates } = geoLocationData;
    let locationSearch = "";
    const [surveyLocationInfo, setSurveyLocationInfo] = useState([]);
    const [currentTabValue, setCurrentTabValue] = useState(0);
    const isOnline = useSelector(state => state.appReducer.isOnline);
    const classes = useStyles();
    const [alertStatus, setAlertStatus] = useState({ open: false, alertMessage: '' });
    useEffect(() => {
        // at every time when we refresh then lat / long is coming zero
        setTimeout(() => {
            getLocationDetail({ start: startIndex });
        }, mapCoordinates.lat > 0 && mapCoordinates.lng > 0 ? 500 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapCoordinates.lat, mapCoordinates.lng]);



    const getLocationDetail = async ({ start = 0 }) => {
        const { mapCoordinates } = geoLocationData;
        let params = {
            storeName: apis.storeName.REQ_LocationList,
            action: 'list',
            latitude: mapCoordinates.lat || 0,
            longitude: mapCoordinates.lng || 0,
            asArray: 0, start: start || startIndex,
            limit: 300, includeAsset: true,
            includeLocationWithoutLatLong: true
        };
        if (start === 0 || start < locationData.recordCount) {
            const response = await request({ url: apis.Location, params, history, withBar: dispatch, isOfflineModeEnable: true });
            setLocationDataInLocalState(response, start);
        }
    }

    const setLocationDataInLocalState = async (response, start) => {
        // load more in searching not working 
        if (response && response.records) {
            let reorderLogsDataClone = [...locationData.records];
            const reorderLogsData = start > 0 ? Utils.getFilterData(response.records, reorderLogsDataClone, "LocationId") : [];
            setLocationData({ records: start === 0 ? response.records : reorderLogsData, recordCount: response.recordCount });
        }
    }

    const searchLocation = async ({ start = 0 }) => {
        let response = locationData;
        if (!response)
            response = { records: [], recordCount: 0 };

        // When user is connected to the internet then we need to check that the name shouldn't be the same 
        const partern = new RegExp(locationSearch, 'i');
        // when we are not passing the lat / long to the server then it should call the request 
        if (locationSearch?.length > 0) {
            response.records = response.records.filter((item) => partern.test(item?.Name) || partern.test(item?.Code));
            if (!navigator.onLine) {
                setLocationDataInLocalState(response, start);
            } else {
                let params = {
                    storeName: "LocationList",
                    action: 'list', latitude: 0, longitude: 0, asArray: 0, start: start, limit: 300, includeAsset: true, locationNameOrCode: locationSearch, sort: 'Name', includeLocationWithoutLatLong: true
                };
                response = await request({ url: apis.Location, params, history, withBar: dispatch, isOfflineModeEnable: true });
                setLocationDataInLocalState(response, start);
            }
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
        } else {
            if (locationSearch?.length === 0) {
                // when the location filter removed need to send the request for get the data
                setStartIndex(0);
                getLocationDetail({ start: 0 });
            }
        }
    }

    const handleChange = (e) => {
        let { value } = e.target;
        let typeToSearch;
        clearTimeout(typeToSearch);
        locationSearch = value;
        typeToSearch = setTimeout(() => {
            if (locationSearch === value) {
                setStartIndex(0);
                searchLocation({ start: 0 });
            }
        }, 1500)

        if (value !== "") {
            setEnableClean("#df0000");
        }
    }

    const handlePaste = (e) => {
        let value = e.clipboardData.getData('Text');
        locationSearch = value;
        if (value) {
            setStartIndex(0);
            searchLocation({ start: 0 });
            setEnableClean("#df0000");
        }
    }

    const handleCleanTextField = () => {
        const searchValue = searchRef?.current?.children[0];
        searchValue.value = "";
        locationSearch = "";
        setStartIndex(0);
        searchLocation({ start: 0 });
        setEnableClean("rgb(205 205 205)");
    }

    const handleMapClick = () => {
        if (locationData?.records?.length > 0) {
            setShowMap(!showMap);
        } else {
            setAlertStatus({ open: true, alertMessage: t('No location found') });
        }
    }
    const getDirectionData = (location) => {
        if (location.Latitude === 0 && location.Longitude === 0) {
            setAlertStatus({ open: true, alertMessage: t('Location has no coordinate') });
            return;
        }

        window.open("https://www.google.com/maps/dir/?api=1&destination=" + location.Latitude + "," + location.Longitude);

    }
    const getDoneSurveyTypeData = async (locationId) => {
        let locationSurveyData = [];
        let params = { action: 'getLocationSurveyTypeList', asArray: 0, start: 0, limit: 0, locationId: locationId, storeName: apis.storeName.REQ_LocationSurveyTypeData };
        const response = await request({
            url: apis.Survey, params, history, withBar: dispatch, isOfflineModeEnable: true, defaultData: {
                records: []
            }
        });
        if (response?.data?.surveyTypeLocationInfo?.length > 0) {
            locationSurveyData = response.data.surveyTypeLocationInfo;
        }
        return locationSurveyData;
    }

    const onLocationClick = async (location) => {
        let surveyTypeLocationInfo = await getDoneSurveyTypeData(location.LocationId);
        setSurveyLocationInfo(surveyTypeLocationInfo);
        let surveyDetails = await getSurveyType(location, surveyTypeLocationInfo);
        if (surveyDetails && surveyDetails.surveyTypeId) {
            history.push({
                pathname: `/Survey/${location.LocationId}/${surveyDetails.surveyTypeId}`,
                state: { clientId: surveyDetails.clientId, code: surveyDetails.code }
            });
        }

    }
    const fetchMoreData = async () => {
        let index = startIndex + 300;
        setStartIndex(index);
        if (locationSearch) {
            searchLocation({ start: index });
        } else {
            getLocationDetail({ start: index });
        }
    }

    const IsolatedMenu = props => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const { location, index } = props;
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (event) => {
            setAnchorEl(null);
        };


        return (
            <div key={location.LocationId}>
                {index > 0 && <Divider variant="inset" component="li" />}
                <ListItem key={location.LocationId} sx={{ paddingLeft: "0px" }} alignItems="flex-start">
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-haspopup="true" aria-controls={`options-menu-${location.LocationId}`} onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id={`options-menu-${location.LocationId}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            keepMounted
                            onClose={handleClose}
                            className={classes.menu} // Apply custom styles to the menu
                        >
                            <MenuItem onClick={() => {
                                getDirectionData(location);
                            }}>
                                <ListItemIcon>
                                    <Directions fontSize="small" />
                                </ListItemIcon>
                                <ListItemText > {"Directions"}</ListItemText>
                            </MenuItem>
                            <MenuItem disabled={!location.SurveyCount > 0} onClick={() => {
                                onViewSubmittedSurveyClick(location.LocationId);
                            }}
                            >
                                <ListItemIcon >
                                    <Visibility fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{t("View Submitted Survey")}</ListItemText>
                            </MenuItem>

                        </Menu>
                    </ListItemSecondaryAction>
                    <ListItemAvatar >
                        <Avatar sx={{ bgcolor: "primary.main" }} onClick={() => onLocationClick(location)}><PlayArrowIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        className='long-address-handler'
                        primary={location.Name + " " + location.Code}
                        secondary={
                            <React.Fragment>
                                <div>{`${location.Street || ""} ${location.Street2 || ""} ${location.City || ""} ${location.State || ""}`} <Typography sx={{ color: "primary.main", fontSize: "inherit!important" }}>{location.PostalCode} {location.Distance ? location.Distance.toFixed(2) + ' km' : ''} </Typography></div>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </div>)


    }
    const getSurveyType = async (locationData, surveyTypeLocationInfo) => {
        const surveyType = await dbUtil.get('surveyType') || {};
        let sortKeys = [], sortedSurveyType = [];
        let filterSurveyType = (surveyType?.records?.length && surveyType?.records.filter(k => {
            let marketIds = k.Markets;
            let channelIds = k.Channels;
            let classificationIds = k.Classifications;
            const filters = {};
            if (marketIds) {
                marketIds = marketIds.split(',');
                filters['marketIds'] = marketIds && marketIds.some(market => market === locationData.MarketId);
            }
            if (channelIds) {
                channelIds = channelIds.split(',');
                filters['channelIds'] = channelIds && channelIds.some(channel => channel === locationData.LocationTypeId);
            }
            if (classificationIds) {
                classificationIds = classificationIds.split(',')
                filters['classificationIds'] = classificationIds && classificationIds.some(classification => classification === locationData.ClassificationId);
            }
            const filterKeys = Object.keys(filters);
            return filterKeys && filterKeys?.length > 0 && filterKeys.every(row => filters[row] === true);
        })) || [];

        filterSurveyType = (filterSurveyType?.length === 0 && surveyType?.records?.filter(surveyData => { return surveyData.IsDefault })) || filterSurveyType;

        filterSurveyType.map((type) => sortKeys.push(type.SurveyTypeName));
        sortKeys.sort();
        sortKeys.map((sortKey) => {
            for (const type of filterSurveyType) {
                if (type?.SurveyTypeName === sortKey) {
                    sortedSurveyType.push(type);
                    continue;
                }
            }
        })

        if (sortedSurveyType.length > 1) {
            const multipleSurveyType = { surveyTypes: sortedSurveyType, locationId: locationData.LocationId, clientId: locationData.ClientId, code: locationData.Code, locationName: locationData.Name, surveyLocationInfo: surveyLocationInfo?.length || surveyTypeLocationInfo };
            await dbUtil.set('multipleSurveyType', multipleSurveyType);
            history.push({
                pathname: `/Location/SurveyType`,
                state: multipleSurveyType
            });
            return;
        }
        return ({ surveyTypeId: sortedSurveyType[0]?.SurveyTypeId || 1, clientId: locationData.ClientId, code: locationData.Code });
    }



    const onViewSubmittedSurveyClick = (id) => {
        history.push(`/Surveys/${id}`);
    }

    const handleChangeTab = (event, newValue) => {

        if (newValue === 0) {
            setShowMap(false);
            setCurrentTabValue(newValue)
        } else if (newValue === 1) {
            if (!isOnline) {
                Swal({
                    title: 'Oops!', text: "Map currently unavailable", icon: "info"
                });
                return;
            }
            setCurrentTabValue(newValue)
            setShowMap(true);
        }
    }
    const handleCloseAlert = () => {
        setAlertStatus({ open: false, alertMessage: '' });
    }

    return (<>

        <Header handleMapClick={handleMapClick} clientIcon={true} title="Location" isShowMenuOption={true} showMapIcon={true} s howVersion={true} />
        <SecondaryHeader
            search={{ handleSearch: handleChange, handlePaste, handleCleanTextField, enableClean, searchRef }}
            message={{
                primaryMessage: t('You are at') + " " + geoLocationData.currentAddress, icon: <NearMeIcon sx={{ color: "blair.main" }} />
            }}
            showSearch={true} showTab={true} showMessage={true}
            tab={{ currentTabValue: currentTabValue, handleChangeTab: handleChangeTab }}
        />
        < SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={currentTabValue}
            onChangeIndex={handleChangeTab}
        >
            <TabPanel value={currentTabValue} index={0} dir={theme.direction}>
                <div style={{ padding: "1rem 0.7rem 1rem 0.7rem", position: "relative", top: "5rem" }}>
                    <InfiniteScroll
                        dataLength={locationData.records.length}
                        next={fetchMoreData}
                        hasMore={true}
                    >
                        <List variant="fullHeight">
                            {
                                locationData.records.map((location, index) => {
                                    return (<>
                                        <IsolatedMenu location={location} index={index} />
                                    </>);

                                })
                            }
                        </List>
                    </InfiniteScroll>

                </div>
            </TabPanel>
            <TabPanel value={currentTabValue} index={1} dir={theme.direction}>
                <MapSearch locations={locationData.records} handleMapClick={handleMapClick} />
            </TabPanel>
        </SwipeableViews >
        <CommonAlert
            open={alertStatus.open}
            handleClose={handleCloseAlert}
            severity={"error"}
            message={alertStatus.alertMessage}
        />
    </>
    );
}
export default Location;