import { Fragment, useEffect, useState } from 'react';
import { MenuItem, FormControl } from '@mui/material';
import Select from '@mui/material/Select';
import actions from '../../redux/actions';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import resources from '../../Utils/locales.json';

export default function ChangeLanguage() {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const [language, setLanguage] = useState(i18n.language);
    const currentLanguage = useSelector(state => state.appReducer.localLanguage);
    const languages = ["En", ...Object.keys(resources)];
    useEffect(() => {
        i18n.changeLanguage(language);
        dispatch({ type: actions.SET_CURRENT_LOCAL_LANGUAGE, localLanguage: language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, i18n])

    const selectLanguage = (e) => {
        setLanguage(e.target.value)
    }
    return (
        <Fragment>

            <FormControl sx={{ minWidth: 50 }} size="small">
                <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={language}
                    onChange={selectLanguage}
                >
                    <MenuItem value="">
                        {currentLanguage}
                    </MenuItem>
                    {languages.map((v, index) => {
                        return <MenuItem key={index} value={v}>{v}</MenuItem>
                    })}

                </Select>
            </FormControl>

        </Fragment >
    );
}
