import axios from 'axios';
import swal from 'sweetalert';
import actions from '../redux/actions';
import Utils from "../Utils";
import { store } from '../redux/store';
import dbUtil from '../Utils/dbUtil';
const transport = axios.create({ withCredentials: true });

const getFormData = (props) => {
    let formData = new FormData();
    for (let key in props) {
        if (key === 'files') {
            props[key].forEach(file => {
                formData.append(key, file);
            });
        } else {
            formData.append(key, props[key]);
        }
    }
    return formData;
}

const getSavedData = async (storeName, defaultData) => {
    let data = null;
    try {
        data = await dbUtil.get(storeName);
    } catch (ex) {
        console.error(ex);
        data = defaultData;
    }
    return data;
}

const request = async ({ url, params, history, withBar, isOfflineModeEnable = false, defaultData, disableLoader = false, silentMode = false }) => {
    const { dispatch } = store;
    const { storeName } = params || {};
    if (navigator.onLine) {
        if (!disableLoader) {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true })
        }

        let reqParams = {
            method: 'POST',
            credentials: 'include',
            timeout: params && params.timeout ? params.timeout : Utils.HHTP_REQUEST_TIMEOUT,
            url: url,
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        if (params) {
            reqParams.data = getFormData(params);
        }

        try {
            let response = await transport(reqParams);
            let data = response.data;
            if (response) {
                if (typeof withBar != "boolean") {
                    dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
                }
                if (response.status === 200) {
                    let json = response.data;
                    if (json.success === false) {
                        if (json.info === 'Session has expired!') {
                            dispatch({ type: actions.SET_USER_DATA, userData: {} });
                            swal({
                                title: "Your session has expired. Please login again.",
                                text: "",
                                icon: "error",
                                dangerMode: true
                            });
                            history.push('/');
                            return;
                        }
                        else if (response.status === 200) {
                            storeName && await dbUtil.set(storeName, data);
                            return data;
                        }
                    }
                    else {
                        storeName && await dbUtil.set(storeName, data);
                        return data;
                    }
                }
            } else {
                dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
                storeName && await dbUtil.set(storeName, data);
                return data;
            }
        } catch (ex) {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
            dispatch({ type: 'SET_UPLOAD_IN_PROGRESS', showProgress: { show: false, value: 0 } });
            if (ex?.response?.status === 401) {
                dispatch({ type: actions.SET_USER_DATA, userData: {} });
                swal({
                    title: (ex?.response?.data?.info && ex.response.data.info) || "Something went wrong!",
                    text: "",
                    icon: "error",
                    dangerMode: true
                });
                history.push('/');
            } else if (ex?.response?.status === 500) {
                if (isOfflineModeEnable) {
                    return await getSavedData(storeName, defaultData);
                } else {
                    if (!silentMode) {
                        swal({
                            title: (ex?.response?.data?.info && ex.response.data.info) || "Something went wrong!",
                            text: "",
                            icon: "error",
                            dangerMode: true
                        });
                    }

                }
            } else if (ex.code === "ECONNABORTED" && ex?.message.includes("timeout")) {
                if (isOfflineModeEnable) {
                    return await getSavedData(storeName, defaultData);
                } else {
                    if (!silentMode) {
                        swal({
                            title: "Request Timeout. Please try again.",
                            text: "",
                            icon: "error",
                            dangerMode: true
                        });
                    }

                }
            } else {
                return await getSavedData(storeName, defaultData);
            }
        }
    } else {
        return await getSavedData(storeName, defaultData);
    }
}
export default request;