import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Survey from '../Screens/Survey';
import Login from '../Screens/Login';
import Utils from '../Utils/index';
import Location from '../Screens/Location';
import Loader from '../Component/Loader';
import Surveys from '../Screens/Surveys';
import PandingScreen from '../Screens/Pending-Survey';
import actions from '../redux/actions';
import SurveyReport from '../Screens/SurveyReport';
import SurveyType from '../Screens/SurveyType';

const SuspenseLoading = () => <>Loading...</>
const processReLogin = (loginData, history, dispatch) => {
    if (Object.keys(loginData).length === 0 && !loginDataCount) {
        Utils.reLogin(history, dispatch); //re-login on redirect
        loginDataCount++;
        return false;
    }
}
const loggedIn = (loginData) => {
    let message = loginData && loginData.message ? loginData.message : "";
    return (message === "Logged in");
};
let loginDataCount = 0;

const MasterPageAuthenticatedRoute = React.memo(({ component: DynamicComponent, ...rest }) => {
    let loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {};
    const dispatch = useDispatch();
    const history = useHistory();
    return <Route {...rest} render={props => {
        processReLogin(loginData, history, dispatch);
        if (loggedIn(loginData)) {
            return <DynamicComponent {...props} />
        } else {
            if (Object.keys(loginData).length === 0) {
                //    Utils.reLogin(history, dispatch); //re-login on redirect
                return false;
            }
            else {
                return <Redirect to="/" />
            }
        }
    }} />
});

const Routes = () => {
    const routeDirection = Utils.routeDirection;
    const location = useLocation();
    let loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {};
    const dispatch = useDispatch();
    const history = useHistory();
 

    React.useEffect(() => {
        processReLogin(loginData, history, dispatch);
        dispatch({ type: actions.SET_IS_ONLINE, isOnline: navigator.onLine });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginDataCount, loginData]);

    return (
        <Suspense fallback={<SuspenseLoading />}>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path={routeDirection}>
                    <Loader />
                    <Suspense fallback={<SuspenseLoading />}>
                        <Switch location={location} key={location.pathname}>
                            <div style={{ height: "100%", display: 'flex', flexDirection: 'column', marginTop: '4em' }}>
                                <MasterPageAuthenticatedRoute path="/Survey/:LocationId/:SurveyTypeId" exact component={Survey} />
                                <MasterPageAuthenticatedRoute path="/Survey" exact component={Survey} />
                                <MasterPageAuthenticatedRoute path="/Location" exact component={Location} />
                                <MasterPageAuthenticatedRoute path="/Location/SurveyType" exact component={SurveyType} />
                                <MasterPageAuthenticatedRoute path="/Surveys/:LocationId" exact component={Surveys} />
                                <MasterPageAuthenticatedRoute path="/Surveys" exact component={Surveys} />
                                <MasterPageAuthenticatedRoute path="/Survey/:LocationId/:SurveyTypeId/:SurveyId" exact component={Survey} />
                                <MasterPageAuthenticatedRoute path="/Pending-Survey" exact component={PandingScreen} />
                                <MasterPageAuthenticatedRoute path="/SurveyReport" exact component={SurveyReport} />
                            </div>
                        </Switch>
                    </Suspense>
                    <Loader />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default Routes