import React, { useState, useEffect } from "react";
import Header from '../../Component/Header';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material';
import { useHistory } from 'react-router';
import dbUtil from "../../Utils/dbUtil";
import { useTranslation } from "react-i18next";
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const PendingSurvey = () => {
    const history = useHistory();
    const [surveyList, setSurveyList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getSurveyList();
    }, []);

    const getSurveyList = async () => {
        const surveyData = await dbUtil.get('Survey') || [];
        setSurveyList(surveyData);
    }

    return (
        <>
            <Header clientIcon={true} title="Pending Surveys" isComingFromLocation={true} isShowMenuOption={true} />
            <div style={{ padding: "1rem 1rem" }}>
                {
                    surveyList.length > 0 ?
                        <List >
                            {
                                surveyList.map((survey, index) => {
                                    const { RowNumber, SurveyTypeName, Category, SurveyCategory } = survey.surveyTypeData;
                                    const { EndDate } = survey;
                                    return (
                                        <div key={index}>
                                            {index > 0 && <Divider variant="inset" component="li" />}
                                            <ListItem alignItems="flex-start" >
                                                <ListItemAvatar>
                                                    <Avatar><PendingActionsIcon /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={SurveyTypeName}
                                                    secondary={
                                                        <React.Fragment>
                                                            <div>{SurveyCategory}</div>
                                                            <div> {survey.location}</div>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </div>

                                    )
                                })
                            }
                        </List>
                        :
                        <div style={{ textAlign: "center" }}><h3 className="text-center">{t("No pending survey")}</h3></div>
                }
            </div>
        </>
    )
}
export default PendingSurvey;