import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { request, apis } from "../../httpUtil/";
import actions from '../../redux/actions'
import { useDispatch, useSelector, } from 'react-redux';
import Utils from '../../Utils';
import ClientSelect from './ClientSelect';
import logo from "../../Assets/images/login-images/logo.png"
import Geocode from "react-geocode";
import ForgotPassword from './ForgotPassword';
import Swal from 'sweetalert';
import { Button, Stack, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, createTheme, ThemeProvider } from '@mui/material';
import ChangeLanguage from "../../Component/ChangeLanguage";
import { useTranslation } from "react-i18next";
import CommonAlert from '../../Component/CommonAlert';

Geocode.setApiKey(Utils.Google_ApiKey);

const theme = createTheme();

const Login = ({ history }) => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [rememberMe, setRememberMe] = useState("");
	const [alertStatus, setAlertStatus] = useState({ open: false });
	const [openAlert, setOpenAlert] = useState(false);
	const userEmail = useSelector(state => state.appReducer.userEmail);
	const userPassword = useSelector(state => state.appReducer.userPassword);
	const selectClient = useSelector(state => state.appReducer.selectClient);
	const clientsList = useSelector(state => state.appReducer.clientsList);
	const appInfo = useSelector(state => state.appReducer.appInfo);
	const [modelOpen, setModelOpen] = useState(false);
	// set user login email in redux
	const memoizedSetUserLogin = useCallback((e) => {
		dispatch({ type: actions.SET_USER_EMAIL, userEmail: e.target.value, clientsList: [] })
	}, [dispatch]);

	//Set user select client in redux
	const memoizedSetUserPassword = useCallback((e) => dispatch({ type: actions.SET_USER_PASSWORD, userPassword: e.target.value, }), [dispatch]);
	const handleCloseAlert = () => {
		setAlertStatus({ open: false });
	}
	// Request to API for Login
	const processLogin = useCallback(
		async (params, event) => {
			if (event) event.preventDefault();
			const response = await request({ url: apis.login, params, history, withBar: dispatch });
			if (response && response.success) {
				setAlertStatus({ error: "", open: false });
				if (response.showClientCombo) {
					dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: response.clientsList });
				} else {
					if (selectClient) {
						Utils.reLogin(history, dispatch)
					} else {
						let userRoutes = Utils.getAssignedRoutes(response)
						if (userRoutes.length > 0) {
							dispatch({ type: actions.SET_USER_DATA, userData: response, clientId: selectClient });
							history.push('/Location');
						} else {
							setAlertStatus({ error: t("You do not have Permissions to View anything on Dashboard, Contact Admin"), open: true });
						}
					}
				}
			} else if (params) {
				setAlertStatus({ error: response?.message || t("Please check your internet connection"), open: true });
			}
		}, [history, dispatch, selectClient]);

	// On Login Click check and validate values
	const onLogin = useCallback(event => {
		event.stopPropagation();
		event.preventDefault();
		if (!userEmail || !userPassword) {
			setAlertStatus({ error: "Email and password required.", open: true });
			return;
		}

		let params = { Username: userEmail, Password: userPassword, RememberMe: rememberMe, isFromWeb: true, key: selectClient, isFromDashboard: true };
		if (clientsList.length && selectClient === 0) {
			setAlertStatus({ error: t("Please select client"), open: true });
			return;
		}
		processLogin(params, event);
	}, [setAlertStatus, processLogin, userEmail, userPassword, clientsList, rememberMe, selectClient])
	const userData = useSelector(state => state.appReducer.userData);
	const userRoutes = userData ? Utils.getAssignedRoutes(userData) : []
	let isLoggedIn = userData && userData.message && userData.message === "Logged in";

	useEffect(() => {
		Utils.getGeoLocation(dispatch);
		if (isLoggedIn) {
			history.push('/Location');
		}
		else {
			Utils.reLogin(history, dispatch);
		}
	}, [userData])
	const handleClose = () => setAlertStatus({ error: "", open: false });
	const handleForgorPasswordModal = () => {
		setModelOpen(!modelOpen)
	};

	useEffect(() => {
		Utils.setAppInfo(dispatch);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" >
				{/* <CssBaseline /> */}
				<Grid container marginTop={{ xs: 2, sm: 2, md: 2, lg: 20, xl: 20 }} spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Stack direction="row">
							<img alt="company-logo" className="img-fluid" src={logo} />
						</Stack>

					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Box
							marginTop={{ sm: 2, lg: 8 }}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>

							<Typography component="h1" variant="h5">
								{t("Sign in")}
							</Typography>

							<Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									onChange={memoizedSetUserLogin}
									value={userEmail}
									id="email"
									label={t("Username")}
									name="email"
									autoComplete="off"
									autoFocus
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									onChange={memoizedSetUserPassword}
									value={userPassword}
									name="password"
									label={t("Password")}
									type="password"
									id="examplePassword"
									autoComplete="off"
								/>
								<ClientSelect t={t} />
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}

								>

									<FormControlLabel
										control={<Checkbox value="remember" color="primary" checked={!!rememberMe} onChange={event => setRememberMe(event.target.checked)} />}
										label={t("Remember me")}
									/>
									<span className="language-wraper"><ChangeLanguage /></span>
								</Stack>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
								>
									{t("Sign In")}
								</Button>
								<Grid container>
									<Grid item xs>
										<Link href="#" onClick={handleForgorPasswordModal} variant="body2">
											{t("Forgot password?")}
										</Link>
										{modelOpen && <ForgotPassword isOpen={modelOpen} handleCloseModal={handleForgorPasswordModal} />}
									</Grid>

								</Grid>

								{appInfo.version && <div className='version'>{`v ${appInfo.version}`} </div>}

							</Box>

						</Box>
					</Grid>
				</Grid>
				{alertStatus.open &&
					<CommonAlert
						open={alertStatus.open}
						handleClose={handleCloseAlert}
						severity="error"
						message={t(alertStatus.error)}
					/>
				}
			</Container>
		</ThemeProvider>
	);
}
export default memo(Login);