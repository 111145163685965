import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import InfoMessage from "./Component/InfoMessage"

let InitializeJSLogger = {
  initialize: function () {
    window.JsLoggerEx && window.JsLoggerEx.initialize();
  }
}
InitializeJSLogger.initialize();

ReactDOM.render(
  <React.StrictMode>
    <InfoMessage />
    <AddToHomeScreen
      appId='Survey'
      startAutomatically={true}
      startDelay={0}
      lifespan={30}
      skipFirstVisit={true}
      displayPace={0}

      customPromptPlatformDependencies={{
        chromium: {
          images: [
            {
              src: 'images/guidance/chromium.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        edge: {
          images: [
            {
              src: 'images/guidance/chromium.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        iphone: {
          images: [
            {
              src: 'images/guidance/iphone.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        ipad: {
          images: [
            {
              src: 'images/guidance/ipad.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        firefox: {
          images: [
            {
              src: 'images/guidance/firefox.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        samsung: {
          images: [
            {
              src: 'images/guidance/firefox.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        },
        opera: {
          images: [
            {
              src: 'images/guidance/opera.png',
              alt: 'Guide to install application on home screen.'
            }
          ]
        }
      }}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
