import React, { memo, useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Menu, Container, ListItemText, MenuList, MenuItem, Stack, ListItemIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import { apis } from '../httpUtil';
import actions from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import HeaderLogo from '../Component/HeaderLogo';
import ChangeLanguage from "./ChangeLanguage";
import { useTranslation } from "react-i18next";
import Utils from '../Utils';
import PlaceIcon from '@mui/icons-material/Place';
import CommonDialog from './CommonDialog';
import CommonAlert from './CommonAlert';

const Header = ({ title, clientIcon, showMapIcon, onBackClick, isShowMenuOption, showVersion, actionButtons, showDotOnly = false, isShowBackButton = false }) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const { t } = useTranslation();

    const [anchorElUser, setAnchorElUser] = useState(null);
    const isOnline = useSelector(state => state.appReducer.isOnline);
    const userData = useSelector(state => state.appReducer.userData);
    const UserFirstName = userData.tags.FirstName
    const currentLanguage = useSelector(state => state.appReducer.localLanguage);
    const [openLogOutDialog, setLogOutDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const retriveLanguageClass = (lang) => {
        switch (lang) {
            case "Fr":
                return "french-lang-styles"
            default:
                return
        }
    }
    const languageUniqueStyles = retriveLanguageClass(currentLanguage);

    const processLogOut = () => {
        const transport = axios.create({ withCredentials: true });
        let formData = new FormData();
        formData.append("signout", true);
        let params = {
            method: 'POST', url: apis.login, withCredentials: true, data: formData
        };
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        transport(params).then((response) => {
            if (response && response.status === 200) {
                dispatch({ type: actions.RESET_STATE });
                history.push('/');
            }
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
        });
    }
    const onLogout = () => {
        if (navigator.onLine) {
            setLogOutDialog(true);
            return;
        }
        setOpenAlert(true);
        setAlertMessage(t("Please check your internet connection"));

    }
    const onLocationClick = () => {
        history.push('/Location');
    }
    const onPendingScrenClick = () => {
        history.push('/Pending-Survey');
    }
    const onSurveyReportClick = () => {
        history.push('/SurveyReport');
    }
    const onAllSurveyClick = () => {
        history.push('/Surveys');
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleClose = () => {
        setLogOutDialog(false);
    }
    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    return (
        <>
            <AppBar className={`cus-header ${languageUniqueStyles}`} style={{ position: 'fixed' }} >
                <Container maxWidth="false">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <HeaderLogo />
                        </Typography>
                        <Box className={`${!actionButtons ? "app-title" : ""}`} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {<Typography className="header-title">{t(title)}</Typography>}
                        </Box>


                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}
                            className="map-icon"
                        >
                            <ArrowBackIcon sx={{ visibility: isShowBackButton ? "visible" : "hidden" }} onClick={onBackClick} />
                        </Typography>
                        <Box className={`${!actionButtons ? "app-title" : ""}`} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            {<Typography className="header-title">{t(title)}</Typography>}
                        </Box>
                        {actionButtons &&
                            <Box sx={{ flexGrow: 0 }}>
                                {actionButtons}
                            </Box>
                        }
                        {(isShowMenuOption || showDotOnly) &&
                            <Box sx={{ flexGrow: 0 }}>

                                <Stack onClick={handleOpenUserMenu} className="profile-area" direction="row" spacing={1} >
                                    <span className={`connectivity-status-dot ${isOnline ? ' online-dot-color' : 'offline-dot-color'} ${showDotOnly ? 'shift-right' : ""}`} ></span>
                                    {!showDotOnly ? <span >{UserFirstName}</span> : <></>}
                                </Stack>
                                <Menu
                                    sx={{ mt: '40px', ml: "30px", display: showDotOnly ? "none" : "block" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuList>
                                        <MenuItem>
                                            <   ListItemIcon>
                                                <LanguageIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ChangeLanguage />
                                        </MenuItem>
                                        <MenuItem onClick={onLocationClick}>
                                            <ListItemIcon>
                                                <PlaceIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>  {t("Location")}</ListItemText>
                                        </MenuItem>

                                        <MenuItem onClick={onPendingScrenClick}>
                                            <ListItemIcon>
                                                <PendingActionsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>  {t("Pending Survey")}</ListItemText>
                                        </MenuItem>

                                        <MenuItem onClick={onAllSurveyClick}>
                                            <ListItemIcon>
                                                <StorageIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText> {t("All Submitted Surveys")}</ListItemText>
                                        </MenuItem>

                                        <MenuItem onClick={onSurveyReportClick}>
                                            <ListItemIcon>
                                                <AssessmentIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText> {t("Survey Report")}</ListItemText>
                                        </MenuItem>


                                        <MenuItem onClick={onLogout}>

                                            <ListItemIcon>
                                                <LogoutIcon />
                                            </ListItemIcon>
                                            <ListItemText>{t("Log Out")}</ListItemText>

                                        </MenuItem>
                                        {/* <Divider /> */}
                                        {Utils.APP_VERSION && <MenuItem>
                                            <Typography variant="body2" color="text.secondary">
                                                {`Version ${Utils.APP_VERSION}`}
                                            </Typography>
                                        </MenuItem>
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                        }
                    </Toolbar>
                </Container>
            </AppBar>
            <CommonDialog
                open={openLogOutDialog}
                handleClose={handleClose}
                title={t("Are you sure you want to log out?")}
                contentText={t("If you log out now, you will need to enter your login details again to access your account.")}
                handleConfirm={processLogOut}
            />
            <CommonAlert
                open={openAlert}
                handleClose={handleCloseAlert}
                severity="error"
                message={alertMessage}
            />
        </>
    )
}

export default memo(Header)
