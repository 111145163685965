import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import actions from '../../redux/actions'
import Swal from 'sweetalert';
import Button from '@mui/material/Button';
import "./SceneCapture.css";
import Header from "../Header";


async function isBase64UrlImage(base64String) {
    let image = new Image()
    image.src = base64String
    return await (new Promise((resolve) => {
        image.onload = function () {
            if (image.height === 0 || image.width === 0) {
                resolve(false);
                return;
            }
            resolve(true)
        }
        image.onerror = () => {
            resolve(false)
        }
    }))
}
const SceneCapture = function ({ matrix, setMatrix, picturesTaken, setPicturesTaken }) {
    const [activeCell, _setActiveCell] = useState({ row: 1, col: 1 });
    const [isImageCellClicked, setIsImageCellClicked] = useState(false);
    const [clickCellToActivate, setClickCellToActivate] = useState(false);
    const activeCellRef = useRef(activeCell);
    const fileSelectRef = useRef(null);
    const setActiveCell = (value) => {
        activeCellRef.current = value;
        _setActiveCell(value);
    }


    const onTakeImage = async function (e) {
        let target = e.target || e.currentTarget;
        // handle click of inner div/ span/ image etc.
        if (target.dataset?.action !== "capture") {
            while (!target.dataset?.col) {
                target = target.parentNode;
            }
            const col = Number(target.dataset.col);
            const row = Number(target.parentNode.dataset.row);
            let hasImage = false;
            for (const matrixRow of matrix) {
                for (const matrixCol of matrixRow) {
                    if (matrixCol) {
                        hasImage = true;
                        break;
                    }
                }
            }
            if (hasImage) {
                const left = matrix[row][col - 1];
                const right = matrix[row][col + 1];
                const top = row === 0 ? undefined : matrix[row - 1][col];
                const bottom = row === (matrix.length - 1) ? undefined : matrix[row + 1][col];
                const cellContent = matrix[row][col];
                const checkImageCellClicked = await isBase64UrlImage(cellContent);
                if (!left && !right && !top && !bottom && !checkImageCellClicked) {
                    Swal('No image in the adjacent cell');
                    return;
                }
                if (checkImageCellClicked) {
                    setIsImageCellClicked(true);
                }
            }
            setActiveCell({ row, col });
            setClickCellToActivate(true);
            return;
        }
    }

    async function onChange(e) {
        e.preventDefault();
        setClickCellToActivate(false);
        const files = e?.target?.files;

        if (!files) {
            console.error("no capture found");
            return;
        }

        const filesArray = Array.from(files).map((file) =>
            URL.createObjectURL(file)
        );
        Array.from(files).map(
            (file) => URL.revokeObjectURL(file) // avoid memory leak
        );


        const { row, col } = activeCell;
        matrix[row][col] = filesArray[0];
        const cellHasCaptureAlready = picturesTaken.findIndex(({ row, col }) => row === activeCell.row && col === activeCell.col);
        let rowIncr = row;
        let colIncr = col;
        let updatedPictureTaken = picturesTaken;

        if (row === 0) {
            rowIncr = 1;
            updatedPictureTaken = picturesTaken.map((item) => {
                const newRow = Number(item.row) + 1;
                const newItem = { ...item, row: newRow };
                return newItem;
            })
        }
        if (col === 0) {
            colIncr = 1;
            updatedPictureTaken = updatedPictureTaken.map((item) => {
                const newCol = Number(item.col) + 1;
                const newItem = { ...item, col: newCol };
                return newItem;
            })

        }

        if (cellHasCaptureAlready === -1) {
            setPicturesTaken([...updatedPictureTaken, { file: files[0], img64: filesArray, row: rowIncr, col: colIncr }])
        } else {
            const pictureTakenCopy = updatedPictureTaken;
            pictureTakenCopy[cellHasCaptureAlready] = { file: files[0], img64: filesArray, row: rowIncr, col: colIncr };
            setPicturesTaken(pictureTakenCopy);
        }

        const cellCount = matrix[0].length;
        const rowCount = matrix.length;
        const newRow = [];
        for (let i = 0; i < cellCount; i++) {
            newRow.push(null);
        }
        let newMatrix = matrix;
        if (row === 0) {
            newMatrix = [newRow, ...matrix];
        } else if (row === rowCount - 1) {
            newMatrix = [...matrix, newRow];
        }

        if (col === 0 || col === (cellCount - 1)) {
            const insertPosition = col === 0 ? 0 : cellCount;
            for (let i = 0, count = newMatrix.length; i < count; i++) {
                newMatrix[i].splice(insertPosition, 0, null)
                newMatrix[i] = [...newMatrix[i]];
            }
        }
        // todo: batch
        setActiveCell({ row: -1, col: -1 });
        setMatrix(newMatrix);
    }

    useEffect(() => {
        if (fileSelectRef?.current !== null) {
            fileSelectRef.current.click();
        }
    }, [])

    useEffect(() => {
        if (clickCellToActivate && picturesTaken.length >= 1) {
            if (fileSelectRef !== null && fileSelectRef.current !== null) {
                fileSelectRef.current.click();
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickCellToActivate])

    const grid = useMemo(() => {
        return matrix.map((row, rowIndex) => {
            return (<div key={rowIndex} data-row={rowIndex}>
                {row.map((col, colIndex) => {
                    let CellContent;
                    let isCellActive = activeCell.col === colIndex && activeCell.row === rowIndex;
                    if (isCellActive) {
                        const hasCapture = matrix[rowIndex][colIndex];
                        CellContent = isImageCellClicked ? <input ref={fileSelectRef} type="file" accept="image/*" capture="environment" alt="Choose image" onChange={(e) => onChange(e, true)} />
                            : (hasCapture ? <img src={hasCapture} alt="" /> : <input ref={fileSelectRef} type="file" accept="image/*" capture="environment" alt="Choose image" onChange={(e) => onChange(e, true)} />)
                    } else if (matrix[rowIndex][colIndex]) {
                        CellContent = <img src={matrix[rowIndex][colIndex]} alt="" />
                    } else {
                        CellContent = <span></span>
                    }
                    return (<div className={isCellActive ? "active" : ""} key={colIndex} data-col={colIndex} >
                        {CellContent}
                    </div>)
                })}

            </div>)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matrix, activeCell])

    return (
        <div onClick={onTakeImage} >
            {grid}
        </div>
    )
};


const Screen = function (props) {
    const dispatch = useDispatch();
    const questNum = props.captureSceneIndex;
    let serialNumbersByIndex = useSelector(state => state.appReducer.captureSceneSerialNumbersClone);
    let matrix = useSelector(state => state.appReducer.captureMatrixClone);
    let currentMatrix = Object.keys(matrix).length > 0 && matrix[questNum] ? matrix[questNum] : [[null, null, null], [null, null, null], [null, null, null]];
    let picturesTaken = useSelector(state => state.appReducer.scenePicturesTakenClone);
    let currentPicturesTaken = Object.keys(picturesTaken).length > 0 && picturesTaken[questNum] ? picturesTaken[questNum] : [];

    const setMatrix = (localMatrix) => {
        let newMatrix = Object.assign({}, matrix);
        newMatrix[questNum] = localMatrix;
        dispatch({ type: actions.SET_CAPTURE_MATRIX_CLONE, captureMatrixClone: newMatrix });
    }
    const setPicturesTaken = (pictures) => {
        let newPictures = Object.assign({}, picturesTaken);
        newPictures[questNum] = pictures;
        serialNumbersByIndex[questNum] = props.currentCaptureSerialNumber;
        dispatch({ type: actions.SET_SCENE_PICTURES_CLONE, scenePicturesTakenClone: newPictures });
        dispatch({ type: actions.SET_CAPTURE_SCENE_QUESTION_IDS_CLONE, captureSceneQuestionIdsClone: questNum })
        dispatch({ type: actions.SET_CAPTURE_SCENE_SERIAL_NUMBERS_CLONE, captureSceneSerialNumbersClone: serialNumbersByIndex });
    }


    const actionButtons = <>
        <Button className="btn btn-somatus-outline cancel" onClick={() => props.cancelCapture(questNum)}>Cancel</Button>
        {currentPicturesTaken.length > 0 && <Button className="btn btn-somatus-outline save" sx={{ background: "#fff", color: "primary", marginRight: "3px" }} data-actualquestion={props.captureActQuest} data-questionnumber={props.captureQuestNum} data-scenecaptureid={questNum} data-name={props.captureQuestName} data-type={props.captureQuestType} data-matrix={true} onClick={(e) => props.onChange(e, { isFile: true, isMultiFile: false, matrix: picturesTaken })} >Save</Button>}
    </>;

    return (
        <>
            <Header title={props.locationName} actionButtons={actionButtons} />
            <div className="scene-capture" >
                <SceneCapture matrix={currentMatrix} setMatrix={setMatrix} picturesTaken={currentPicturesTaken} setPicturesTaken={setPicturesTaken} />
            </div>
        </>
    );

}
export default React.memo(Screen);
