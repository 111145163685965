import React from 'react';
import ShowProgress from './ShowProgress';
import { BarWave } from "react-cssfx-loading";
import { useSelector } from 'react-redux';

const Loader = props => {
    const loaderOpen = useSelector(state => state.appReducer.loaderOpen);
    const progress = useSelector(state => state.appReducer.showProgress);
    return (loaderOpen ? <div className="loader">
        <BarWave color="#FFFFFF" width="40px" height="15px" />
        {progress.show ? <ShowProgress value={progress.value} step={progress.step} totalSteps={progress.totalSteps} /> : <></>}
    </div>
        : null
    );

}
export default Loader;